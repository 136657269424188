import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import type { LoyaltyCurrency } from '../partnership';
import { getLoyaltyCurrencies } from '../partnership/api';
import { initialLoyaltyCurrenciesContextValue } from './LoyaltyCurrenciesContext.constants';
import type { LoyaltyCurrenciesContextValue } from './LoyaltyCurrenciesContext.types';

export const LoyaltyCurrenciesContext = createContext<LoyaltyCurrenciesContextValue>(initialLoyaltyCurrenciesContextValue);

export const LoyaltyCurrenciesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: loyaltyCurrencies, isLoading: areLoyaltyCurrenciesLoading, error } = useQuery(
    'getLoyaltyCurrencies',
    () => getLoyaltyCurrencies(),
  );

  const [selectedLoyaltyCurrency, setSelectedLoyaltyCurrency] = useState<LoyaltyCurrency>(initialLoyaltyCurrenciesContextValue.selectedLoyaltyCurrency);

  useEffect(() => {
    if (!!loyaltyCurrencies && !error) {
      const firstAvailableLoyaltyCurrency: LoyaltyCurrency | undefined = Object.values(loyaltyCurrencies)?.[0];
      setSelectedLoyaltyCurrency(firstAvailableLoyaltyCurrency ?? initialLoyaltyCurrenciesContextValue.selectedLoyaltyCurrency);
    }
  }, [loyaltyCurrencies, error]);

  return (
    <LoyaltyCurrenciesContext.Provider value={{
      selectedLoyaltyCurrency,
      setSelectedLoyaltyCurrency,
      loyaltyCurrencies,
      areLoyaltyCurrenciesLoading,
      error: error as Error | undefined,
    }}>
      {children}
    </LoyaltyCurrenciesContext.Provider>
  );
};
