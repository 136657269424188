import cx from 'classnames';
import React, { type ReactNode } from 'react';
import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import styles from './ExclusiveBadge.module.scss';
import { usePresenter } from './ExclusiveBadge.presenter';
import type { ExclusiveBadgeProps } from './ExclusiveBadge.types';

export const ExclusiveBadge: React.FC<ExclusiveBadgeProps> = (props) => {
  const {
    theme,
    className,
    textValue,
    iconInfo,
    imageUrl,
  } = usePresenter(props);

  const exclusiveBadgeIcon: ReactNode = iconInfo && (
    <Icon
      asset={iconInfo?.iconAsset}
      style={iconInfo?.iconStyle}
      className={cx(styles.icon, styles.unShrinkableIconAndImage)}
    />
  );

  const exclusiveBadgeImage: ReactNode = imageUrl && (
    <div className={styles.imageContainer}>
      <Image
        imageSrc={imageUrl}
        className={cx(styles.image, styles.unShrinkableIconAndImage)}
      />
    </div>
  );

  return (
    <div className={cx(styles.exclusiveBadge, styles[`${theme}ExclusiveBadge`], className)}>
      {exclusiveBadgeIcon}
      {exclusiveBadgeImage}
      <Text
        size='ExtraSmall'
        style='Regular'
        colour='InteractionBlue70'
        value={textValue}
        className={styles.badgeText}
      />
    </div>
  );
};
