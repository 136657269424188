import cx from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import { HighlightedMessage } from '../HighlightedMessage';
import styles from './SignInDialog.module.scss';
import { usePresenter } from './SignInDialog.presenter';
import type { SignInDialogProps } from './SignInDialog.types';

export const SignInDialog: React.FC<SignInDialogProps> = (props) => {
  const {
    isSignInDialogShown,
    onSignInDialogClick,
    className,
  } = usePresenter(props);

  if (!isSignInDialogShown) {
    return null;
  }

  return (
    <HighlightedMessage
      iconAsset='Lock'
      iconStyle='BaseDark'
      content={
        <Trans
          i18nKey='signInDialog.title'
          components={{ underline: <span className={styles.underlineText} /> }}
        />
      }
      background='yellow'
      onClick={onSignInDialogClick}
      className={cx(styles.signInDialog, className)}
      classes={{ icon: styles.icon }}
    />
  );
};
