import React from 'react';
import { Trans } from 'react-i18next';
import { AriaRoles } from '../../../lib/types';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import styles from './SignInDisclaimer.module.scss';
import { usePresenter } from './SignInDisclaimer.presenter';
import type { SignInDisclaimerProps } from './SignInDisclaimer.types';

export const SignInDisclaimer: React.FC<SignInDisclaimerProps> = (props) => {
  const {
    isSignInDisclaimerShown,
    signInDisclaimerRef,
    onSignInDisclaimerClick,
  } = usePresenter(props);

  if (!isSignInDisclaimerShown) {
    return null;
  }

  return (
    <button
      ref={signInDisclaimerRef}
      onClick={onSignInDisclaimerClick}
      className={styles.signInDisclaimer}
    >
      <Icon
        asset='Lock'
        style='CoreBlue50'
        className={styles.icon}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='BaseDark'
        value={
          <Trans
            i18nKey='signInDisclaimer.title'
            components={{ underline: <span className={styles.underlineText} /> }}
          />
        }
        ariaRole={AriaRoles.ALERT}
        ariaAtomic={true}
      />
    </button>
  );
};
