declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const {
  REACT_APP_DOMAIN: DOMAIN = window.env?.REACT_APP_DOMAIN,
  REACT_APP_BFF_URL: BFF_URL = window.env?.REACT_APP_BFF_URL,
  REACT_APP_CLOUDFRONT_URL: CLOUDFRONT_URL = window.env?.REACT_APP_CLOUDFRONT_URL,
  REACT_APP_FRONTEND_URL: FRONTEND_URL = window.env?.REACT_APP_FRONTEND_URL,
  REACT_APP_SESSION_COOKIE_NAME: SESSION_COOKIE_NAME = window.env?.REACT_APP_SESSION_COOKIE_NAME,
  REACT_APP_GTM_ID: GTM_ID = window.env?.REACT_APP_GTM_ID,
  REACT_APP_UNAUTHORIZED_URL: UNAUTHORIZED_URL = window.env?.REACT_APP_UNAUTHORIZED_URL || 'https://api-it.capitalone.com/oauth2/authorize',
  REACT_APP_PRIVACY_URL: PRIVACY_URL = window.env?.REACT_APP_PRIVACY_URL || 'https://www.capitalone.com/privacy/online-privacy-policy',
  REACT_APP_OPTIMIZELY_SDK: OPTIMIZELY_SDK = window.env?.REACT_APP_OPTIMIZELY_SDK,
  SENTRY_DSN: SENTRY_DSN_VALUE = window.env?.SENTRY_DSN || 'https://692cb96b7186476f86653a9406d1d195@o130096.ingest.sentry.io/6262364',
  REACT_APP_LIVE_CHAT_URL: LIVE_CHAT_URL = window.env?.REACT_APP_LIVE_CHAT_URL || 'https://home-c8.incontact.com/incontact/chatclient/chatclient.aspx?poc=2b86ff04-756e-4599-9a18-f9b4bbfd443d&bu=4593638',
  REACT_APP_CLIENT_ID: CLIENT_ID = window.env?.REACT_APP_CLIENT_ID || '4c3a3ed644424b42b59a4a4f600834fd',
} = process.env;

export const IS_DEV = process.env?.REACT_APP_IS_DEV === 'true' || window.env?.REACT_APP_IS_DEV === 'true';
