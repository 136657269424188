import type { BillingAddress } from '../components/organisms/AddressInfo';
import type { Account, AccountCardDetail, AccountTags, AssociatedAccount } from '../modules/auth/types';
import type { LoyaltyCurrencies, LoyaltyCurrency } from '../modules/partnership';
import { DEFAULT_SELECTED_CARD_INDEX, IsIntegerRewardUnit } from './constants';
import { isAccountCardValidForProvidedTags } from './eventUtils';
import { getUniqueStringValuesFromObjects } from './objectUtils';
import { convertStringToSnakeCase, formatNumberToLocaleString, getFiveDigitsZipCode, getLoyaltyUnitTag, getProcessingNetworkTag, getProgramTypeTag, getTwoDigitsCountryCode, parseNumber } from './util';

/**
 * Extracts and formats the details for an account card based on the provided account data.
 * @returns {AccountCardDetail} - An object with formatted account card details.
 */
export const getAccountCardDetail = (params: {
  /** Associated account object containing loyalty program details */
  associatedAccount: AssociatedAccount;
  /** Available loyalty currencies with their conversion rates */
  loyaltyCurrencies: LoyaltyCurrencies | undefined;
}): AccountCardDetail => {
  const { associatedAccount, loyaltyCurrencies } = params;

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    address,
    phone: phoneNumber = '',
    loyalty_program: {
      /** Image URL of the account card */
      program_logo_url: accountCardImageUrl,
      /** Last 4 digits of the account card number as string, e.g. '1234' */
      last_four: accountCardLastFourDigits = '',
      /** Number of available reward units, e.g. 123456 */
      number_of_units: numberOfUnits,
      /** Number of dollars that is equivalent to 1 reward unit, e.g. 0.01 */
      redeem_per_dollar: redeemPerRewardUnit, // Note: API property name 'redeem_per_dollar' is misleading, it should be called 'redeem_per_unit'.
      /** Loyalty unit display name from API, e.g. 'miles', 'rewards cash', 'points' */
      unit_display_name: unitDisplayName = '',
      /** Loyalty unit name from API, e.g. 'MILES', 'REWARDS CASH', 'POINTS'. To be used to call API to create order. */
      loyalty_unit_name: loyaltyUnitName = '',
      program_type: accountProgramType = '',
    },
  } = associatedAccount;

  /** Lower cased reward unit name from API, e.g. 'miles', 'rewards cash', 'points', etc. */
  const apiRewardUnitName: string = (unitDisplayName || loyaltyUnitName).toLowerCase();

  /** True only for miles and points units */
  const isIntegerRewardUnit: boolean = IsIntegerRewardUnit[apiRewardUnitName] ?? false;

  /** Validated total number of available reward units, e.g. 123456. 0 if the value is not a number. */
  const rewardUnitsTotal: number = parseNumber(numberOfUnits, { minValue: 0, allowDecimals: !isIntegerRewardUnit }) ?? 0;

  /** Loyalty currency object based on the reward unit name */
  const loyaltyCurrency: LoyaltyCurrency | undefined = apiRewardUnitName && loyaltyCurrencies
    ? loyaltyCurrencies[convertStringToSnakeCase(apiRewardUnitName)]
    : undefined;

  /** Validated number of reward units that is equivalent to $1, e.g. 100. 1 if the value is not a number. */
  const rewardUnitsPerDollar: number = parseNumber(1 / (loyaltyCurrency?.rate ?? redeemPerRewardUnit), { minValue: 0, allowDecimals: true }) ?? 1;

  /** Lower cased reward unit name, e.g. 'miles', 'rewards cash', 'points', etc. */
  const rewardUnitName: string = loyaltyCurrency?.display_name || apiRewardUnitName;

  /** Formatted number of available reward units, e.g. '123,456 miles', '123,456 points', '$123,456' */
  const formattedRewardUnitsTotal: string = rewardUnitName
    ? formatNumberToLocaleString({ num: rewardUnitsTotal, unitName: rewardUnitName, shouldIncludeUnitName: true })
    : '';

  const billingAddress: BillingAddress = {
    firstName,
    lastName,
    streetAddress: address?.address_line1 || '',
    streetAddressLine2: address?.address_line2 || '',
    city: address?.city || '',
    state: address?.state_code || '',
    postalCode: getFiveDigitsZipCode({ countryCode: address?.country_code || '', postalCode: address?.postal_code || '' }),
    country: getTwoDigitsCountryCode(address?.country_code || ''),
    phoneNumber,
  };

  // Used for events
  const accountLoyaltyUnitTag: string | undefined = getLoyaltyUnitTag(associatedAccount);
  const accountProgramTypeTag: string | undefined = getProgramTypeTag(associatedAccount);
  const accountProcessingNetworkTag: string | undefined = getProcessingNetworkTag(associatedAccount);

  return {
    accountCardImageUrl,
    accountCardLastFourDigits,
    rewardUnitsTotal,
    rewardUnitsPerDollar,
    rewardUnitName,
    loyaltyCurrency,
    isIntegerRewardUnit,
    formattedRewardUnitsTotal,
    billingAddress,
    accountLoyaltyUnitTag,
    accountProgramTypeTag,
    accountProcessingNetworkTag,
    accountProgramType,
  };
};

/**
 * Retrieves an array of account card details for associated accounts of the provided main account.
 * If there are no associated accounts then the main account will be used instead.
 * @returns {AccountCardDetail[]} - Array of AccountCardDetail objects for each associated account.
 */
export const getAccountCardDetails = (params: {
  /** The main account object which may contain associated accounts */
  account: Account | undefined;
  /** Available loyalty currencies with their conversion rates */
  loyaltyCurrencies: LoyaltyCurrencies | undefined;
}): AccountCardDetail[] => {
  const { account, loyaltyCurrencies } = params;

  // Return an empty array if no account data is provided
  if (!account) {
    return [];
  }

  // Use the main account if there are no associated accounts
  const associatedAccounts: readonly AssociatedAccount[] = account.associatedAccounts.length ? account.associatedAccounts : [account];

  const accountCardDetails: AccountCardDetail[] = associatedAccounts.map((associatedAccount: AssociatedAccount) => getAccountCardDetail({ associatedAccount, loyaltyCurrencies }));

  return accountCardDetails;
};

/**
 * Extracts and generates unique tag arrays for loyalty unit, program type, and processing network tags.
 * - This function processes a list of account card details and extracts unique tag values for each category.
 * - It uses the `getUniqueStringValuesFromObjects` function to get unique values for each tag category.
 * @returns {AccountTags} - An object containing arrays of unique tags for loyalty unit, program type, and processing network.
 */
export const getAccountTags = (params: {
  /** List of account card details to extract tags from */
  accountCardDetails: AccountCardDetail[];
}): AccountTags => {
  const { accountCardDetails } = params;

  // Get unique loyalty unit tags
  const accountLoyaltyUnitTags: string[] = getUniqueStringValuesFromObjects({ items: accountCardDetails, propertyName: 'accountLoyaltyUnitTag' });

  // Get unique program type tags
  const accountProgramTypeTags: string[] = getUniqueStringValuesFromObjects({ items: accountCardDetails, propertyName: 'accountProgramTypeTag' });

  // Get unique processing network tags
  const accountProcessingNetworkTags: string[] = getUniqueStringValuesFromObjects({ items: accountCardDetails, propertyName: 'accountProcessingNetworkTag' });

  // Return an object containing all the unique tags
  return { accountLoyaltyUnitTags, accountProgramTypeTags, accountProcessingNetworkTags };
};


// TODO: ND: Add Unit Tests
export const getFirstEligibleAccountCardIndex = (params: {
  tags: string[] | undefined,
  accountCardDetails: AccountCardDetail[],
  isExclusiveEvent: boolean
}): number => {
  const { tags, accountCardDetails, isExclusiveEvent } = params;

  if (!isExclusiveEvent || !tags || !tags.length) {
    return DEFAULT_SELECTED_CARD_INDEX;
  }

  const indexOfFirstValidCard = accountCardDetails.findIndex((accountCardDetail) =>
    isAccountCardValidForProvidedTags(accountCardDetail, tags),
  );

  return indexOfFirstValidCard !== -1 ? indexOfFirstValidCard : DEFAULT_SELECTED_CARD_INDEX;

};
