import type { LimitedAccessModalTheme, LimitedAccessModalThemeProps } from './LimitedAccessModal.types';

export const LimitedAccessModalPropsByTheme = {
  light: {
    titleTextType: {
      mobile: 'Subheading',
      desktop: 'Body',
    },
    titleTextSize: {
      mobile: 'Medium',
      desktop: 'Large',
    },
    titleTextColor: 'BaseDark',
    closeIconColor: 'BaseDark',
    contentTextSize: {
      mobile: 'Large',
      desktop: 'Medium',
    },
    contentTextColor: 'BaseDark',
    contentTextAlign: {
      mobile: 'Center',
      desktop: 'Left',
    },
    actionButtonStyle: 'Contained',
  },
  dark: {
    titleTextType: {
      mobile: 'Subheading',
      desktop: 'Body',
    },
    titleTextSize: {
      mobile: 'Medium',
      desktop: 'Large',
    },
    titleTextColor: 'BaseLight',
    closeIconColor: 'White',
    contentTextSize: {
      mobile: 'Large',
      desktop: 'Medium',
    },
    contentTextColor: 'BaseLight',
    contentTextAlign: {
      mobile: 'Center',
      desktop: 'Left',
    },
    actionButtonStyle: 'ContainedWhite',
  },
} as const satisfies Record<LimitedAccessModalTheme, LimitedAccessModalThemeProps>;
