import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import styles from './SignInBanner.module.scss';
import { usePresenter } from './SignInBanner.presenter';
import type { SignInBannerProps } from './SignInBanner.types';

export const SignInBanner: React.FC<SignInBannerProps> = (props) => {
  const {
    onSignInBannerClick,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={styles.signInBanner}>
      <div className={styles.contentContainer}>
        <Text
          size='Medium'
          type='Subheading'
          style='SemiBold'
          colour='SubduedDark'
          align='Center'
          value={t('signInBanner.title')}
        />
        <Button
          type='Text'
          style='Contained'
          size='Medium'
          focusable={true}
          text={{
            value: t('common.signIn'),
            className: styles.signInButtonText,
          }}
          onClick={onSignInBannerClick}
          className={styles.signInButton}
        />
      </div>
    </div>
  );
};
