import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLs } from '../../../lib/constants';
import { AuthContext } from '../../../modules/auth';
import { getCurrentHistoryState } from '../../../modules/navigation/Navigation.utils';
import type { LimitedAccessBlockPresenterProps, LimitedAccessBlockProps } from './LimitedAccessBlock.types';

export const usePresenter = (props: LimitedAccessBlockProps): LimitedAccessBlockPresenterProps => {
  const { isSignedIn } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isSignedIn) {
      navigate(URLs.HomePage);
    }
  }, [isSignedIn, navigate]);

  const onContinueBrowsing = useCallback(() => {
    const goBackHistoryState: unknown = getCurrentHistoryState();

    // If goBackHistoryState is set then it is not the first page visit
    if (goBackHistoryState) {
      // Navigate to the previous page if it is not the first page visit
      // -1 = Redirect to event page with 'historyState' parameter
      // -2 = Redirect to home page with 'code' parameter after successful sign-in
      // -3 = Previous page (when user started sign-in)
      navigate(-3);
    } else {
      // Navigate to All Events page when it is the first page visit
      navigate(URLs.AllEventsPage, { replace: true });
    }
  }, [navigate]);

  return {
    ...props,
    onContinueBrowsing,
  };
};
