import { useCallback } from 'react';
import { redirectToSignInURL } from '../../../modules/navigation/Navigation.utils';
import type { SignInBannerPresenterProps, SignInBannerProps } from './SignInBanner.types';

export const usePresenter = (props: SignInBannerProps): SignInBannerPresenterProps => {
  const onSignInBannerClick = useCallback(() => redirectToSignInURL(), []);

  return {
    ...props,
    onSignInBannerClick,
  };
};
