import { decodeJwt, JWTPayload } from 'jose';
import { getSessionCookie } from '../../lib/util';

let warningTimeout;

let warningCallback: (() => void) | undefined;
let logoutCallback: (() => void) | undefined;

export const resetTimer = (): void => {
  let time;
  let ttl;

  const sessionCookie: string = getSessionCookie() || '';
  const jwt: string | JWTPayload | null = decodeJwt(sessionCookie);

  if (jwt && !(typeof jwt === 'string')) {
    ttl = jwt.ttl;
  }

  if (ttl) {
    const today = new Date().getTime();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const future = new Date(ttl).getTime();
    time = future - today;
  }

  if (logoutCallback && (time <= 0 || ttl === undefined)) {
    logoutCallback();
  }

  if (warningTimeout) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(warningTimeout);
    warningTimeout = undefined;
  }
  warningTimeout = setTimeout(() => {
    if (warningCallback) {
      warningCallback();
    }
  }, Math.min(40 * 60 * 1000, (time - 5 * 60 * 1000)));
};

export const startTimer = (
  warning: () => void,
  logout: () => void,
): void => {
  warningCallback = warning;
  logoutCallback = logout;
  resetTimer();
};

export const stopTimer = (): void => {
  if (warningTimeout) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(warningTimeout);
    warningTimeout = undefined;
  }
  warningCallback = undefined;
  logoutCallback = undefined;
};
