import { getSubCategoriesByCategory } from '../../../lib/categoryUtils';
import { CATEGORY_PARAM, EXCLUSIVE_CATEGORY, EXCLUSIVE_CATEGORY_MAP, TAB_KEY_PARAM, URLs } from '../../../lib/constants';
import { getPerformerIdFromSlug } from '../../../lib/performerUtils';
import { addTagFilterToEventQueryParams } from '../../../lib/util';
import type { AccountTags } from '../../../modules/auth/types';
import { CATEGORIES, SPORTS_LEAGUE_SUB_CATEGORY_IDS } from '../../../modules/categories';
import type { CategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import type { Category, EventsQueryParams, Region, SubCategory } from '../../../modules/partnership';
import { convertDateFilterStateToQueryParams, type DateFilterState, type DateRange } from '../../organisms/DateFilter';
import { convertPresetFilterStateToQueryParams, type PresetFilterOption } from '../../organisms/PresetFilter';
import { convertRegionFilterStateToQueryParams, type RegionFilterState } from '../../organisms/RegionFilter';
import type { TabsMap } from '../../organisms/TabBar';
import { ApiParamsByTabKey, ExclusivesTabCategoryFilterOptions, THEATER_EXCLUDED_SUB_CATEGORY_IDS } from './AllEventsPage.constants';
import type { AllEventsTabKey, ExcludedSubCategoryName } from './AllEventsPage.types';

/**
 * Builds the URL for the All Events page with the given query parameters.
 * @returns {string} - The constructed URL with query parameters.
 */
export const buildAllEventsPageUrlWithParams = (params: {
  /** The active tab key. */
  tabKey?: AllEventsTabKey;
  /** The state of the region filter. */
  regionFilterState?: RegionFilterState;
  /** The state of the date filter. */
  dateFilterState?: DateFilterState;
  /** The state of the category filter. */
  categoryFilterState?: PresetFilterOption;
}): string => {
  const { tabKey, regionFilterState, dateFilterState, categoryFilterState } = params;

  const queryParams = new URLSearchParams();

  if (tabKey) {
    queryParams.set(TAB_KEY_PARAM, tabKey);
  }

  const filterQueryParams: Record<string, string> = {
    ...convertRegionFilterStateToQueryParams(regionFilterState),
    ...convertDateFilterStateToQueryParams(dateFilterState),
    ...convertPresetFilterStateToQueryParams(CATEGORY_PARAM, categoryFilterState),
  };

  Object.entries(filterQueryParams).forEach(([queryParamName, queryParamValue]) => {
    queryParams.set(queryParamName, queryParamValue);
  });

  // Do not use queryParams.size. For some reason it returns undefined sometimes.
  const queryString: string = Array.from(queryParams.keys()).length ? `?${queryParams.toString()}` : '';

  return `${URLs.AllEventsPage}${queryString}`;
};

/**
 * Constructs the tabs for the All Events page.
 * @returns {TabsMap<AllEventsTabKey>} - The constructed tabs map.
 */
export const getAllEventsPageTabs = (params: {
  /** The state of the region filter. */
  regionFilterState?: RegionFilterState;
  /** The state of the date filter. */
  dateFilterState?: DateFilterState;
}): TabsMap<AllEventsTabKey> => {
  const { regionFilterState, dateFilterState } = params;

  return {
    allEvents: {
      titleKey: 'allEventsPage.tabBar.allEvents',
      iconAsset: 'AllEvents',
      // All Events tab is selected by default, so pass undefined tabKey
      href: buildAllEventsPageUrlWithParams({ tabKey: undefined, regionFilterState, dateFilterState }),
    },
    exclusives: {
      titleKey: 'allEventsPage.tabBar.exclusives',
      iconAsset: 'Exclusives',
      // Exclusives tab must always reset region and date selection, so pass undefined values
      href: buildAllEventsPageUrlWithParams({ tabKey: 'exclusives', regionFilterState: undefined, dateFilterState: undefined }),
    },
    music: {
      titleKey: 'allEventsPage.tabBar.music',
      iconAsset: 'Music',
      href: buildAllEventsPageUrlWithParams({ tabKey: 'music', regionFilterState, dateFilterState }),
    },
    sports: {
      titleKey: 'allEventsPage.tabBar.sports',
      iconAsset: 'Sports',
      href: buildAllEventsPageUrlWithParams({ tabKey: 'sports', regionFilterState, dateFilterState }),
    },
    comedy: {
      titleKey: 'allEventsPage.tabBar.comedy',
      iconAsset: 'Comedy',
      href: buildAllEventsPageUrlWithParams({ tabKey: 'comedy', regionFilterState, dateFilterState }),
    },
    theater: {
      titleKey: 'allEventsPage.tabBar.theater',
      iconAsset: 'Theater',
      href: buildAllEventsPageUrlWithParams({ tabKey: 'theater', regionFilterState, dateFilterState }),
    },
  };
};

/**
 * Constructs the category filter options for the given active tab key.
 * @returns {PresetFilterOption[]} - The constructed category filter options.
 */
export const getCategoryFilterOptions = (params: {
  /** Currently active tab key, e.g. exclusives, music, etc. */
  activeTabKey: AllEventsTabKey;
  /** Dynamic categories with sub-categories */
  categories: Category[];
  /** Static categories with sub-categories and performers */
  categoryMenuItemStates: CategoryMenuItemState[];
}): PresetFilterOption[] => {
  const { activeTabKey, categories, categoryMenuItemStates } = params;

  switch (activeTabKey) {
    case 'allEvents':
    case 'comedy': {
      return [];
    }
    case 'exclusives': {
      return ExclusivesTabCategoryFilterOptions;
    }
    case 'music': {
      const subCategories: readonly SubCategory[] = getSubCategoriesByCategory({ categories, categoryId: CATEGORIES.music });
      return subCategories.map(({ id: subCategoryId, name }) => ({ id: subCategoryId.toString(), name }));
    }
    case 'theater': {
      const subCategories: readonly SubCategory[] = getSubCategoriesByCategory({ categories, categoryId: CATEGORIES.theater });
      return subCategories
        // Exclude Comedy sub-category
        .filter(({ id: subCategoryId }) => !THEATER_EXCLUDED_SUB_CATEGORY_IDS.includes(subCategoryId))
        .map(({ id: subCategoryId, name }) => ({ id: subCategoryId.toString(), name }));
    }
    case 'sports': {
      const { subCategories } = categoryMenuItemStates.find(category => category.id.toString() === CATEGORIES.sports.toString()) ?? {};

      if (!subCategories) {
        return [];
      }

      // Include only sports league sub-categories
      return subCategories
        .filter(({ subCategoryId }) => SPORTS_LEAGUE_SUB_CATEGORY_IDS.includes(subCategoryId))
        .map(({ subCategoryId, name }) => ({ id: subCategoryId.toString(), name }));
    }
  }
};

export const getPerformerFilterOptions = (params: {
  /** Currently active tab key, e.g. exclusives, music, etc. */
  activeTabKey: AllEventsTabKey;
  /** Current state of category filter */
  categoryFilterState: PresetFilterOption;
  /** Static categories with sub-categories and performers */
  categoryMenuItemStates: CategoryMenuItemState[];
}): PresetFilterOption[] => {
  const { activeTabKey, categoryFilterState: { id: subCategoryId }, categoryMenuItemStates } = params;

  const { subCategories } = categoryMenuItemStates.find(category => category.name.toLowerCase() === activeTabKey) ?? {};

  const { performers } = subCategories?.find(subCategory => subCategory.subCategoryId?.toString() === subCategoryId.toString()) ?? {};

  return performers ? performers.map(({ slug, name }) => ({ id: getPerformerIdFromSlug(slug) ?? '', name })) : [];
};

/** Checks if MLB Baseball events should be suppressed in the list of events */
export const checkShouldSuppressMLBBaseball = (params: {
  /** Currently active tab key, e.g. exclusives, music, etc. */
  activeTabKey: AllEventsTabKey;
  /** Current state of category filter */
  categoryFilterState: PresetFilterOption | undefined;
}): boolean => {
  const { activeTabKey, categoryFilterState } = params;

  // Suppress MLB Baseball events only on Exclusives tab when category is either not selected or it is Sports
  const sports: keyof typeof EXCLUSIVE_CATEGORY_MAP = 'sports';
  return activeTabKey === 'exclusives' && (!categoryFilterState || categoryFilterState.id === sports);
};

/** Checks if Comedy events should be suppressed in the list of events */
export const checkShouldSuppressComedy = (params: {
  /** Currently active tab key, e.g. exclusives, music, etc. */
  activeTabKey: AllEventsTabKey;
  /** Current state of category filter */
  categoryFilterState: PresetFilterOption | undefined;
}): boolean => {
  const { activeTabKey, categoryFilterState } = params;

  // Suppress Comedy events only on Theater tab when category is not selected
  return activeTabKey === 'theater' && !categoryFilterState;
};

/**
 * Builds the query parameters for fetching events based on the provided filters.
 * @returns {EventsQueryParams} Query parameters for fetching events.
 */
export const buildAllEventsPageStaticApiParams = (params: {
  /** Object that contains loyalty, program, and processing network tags for the account */
  accountTags: AccountTags;
  /** Currently active tab key determining the category of events */
  activeTabKey: AllEventsTabKey;
  /** Region for filtering events */
  region: Region | undefined;
  /** Object containing the start and end dates for filtering events */
  dateRange: Record<keyof DateRange, string>;
  /** Current state of the category filter */
  categoryFilterState: PresetFilterOption | undefined;
  /** Category name to suppress in the list of events */
  excludedSubCategoryName: ExcludedSubCategoryName | undefined;
  /** Current state of the performer filter */
  performerFilterState: PresetFilterOption | undefined;
}): EventsQueryParams => {
  const {
    accountTags,
    activeTabKey,
    region,
    dateRange,
    categoryFilterState,
    excludedSubCategoryName,
    performerFilterState,
  } = params;

  // Construct common API parameters
  const commonStaticApiParams: EventsQueryParams = {
    ...(region ? { region_id: region.id } : {}),
    ...(dateRange.startDate ? { date_start: dateRange.startDate } : {}),
    ...(dateRange.endDate ? { date_end: dateRange.endDate } : {}),
  };

  // Handle the 'exclusives' case separately
  if (activeTabKey === 'exclusives') {
    const exclusiveCategoryName: string = EXCLUSIVE_CATEGORY_MAP[categoryFilterState?.id ?? ''] || EXCLUSIVE_CATEGORY;

    return addTagFilterToEventQueryParams({
      eventQueryParams: {
        ...commonStaticApiParams,
        category: exclusiveCategoryName,
      },
      accountTags,
      shouldExcludeCollapsedEvents: excludedSubCategoryName === 'MLB Baseball', // Exclude MLB Baseball events
    });
  }

  // Merged common and tab specific parameters
  return {
    ...ApiParamsByTabKey[activeTabKey],
    ...commonStaticApiParams,
    ...(categoryFilterState && !Number.isNaN(Number(categoryFilterState.id)) ? { sub_category_id: Number(categoryFilterState.id) } : {}),
    ...(excludedSubCategoryName === 'Comedy' ? { excluded_sub_category_name: excludedSubCategoryName.toLowerCase() } : {}),
    ...(performerFilterState && !Number.isNaN(Number(performerFilterState.id)) ? { performer_id: Number(performerFilterState.id) } : {}),
  };
};
