import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { Footer } from '../../organisms/Footer';
import styles from './LimitedAccessBlock.module.scss';
import { usePresenter } from './LimitedAccessBlock.presenter';
import type { LimitedAccessBlockProps } from './LimitedAccessBlock.types';

export const LimitedAccessBlock: React.FC<LimitedAccessBlockProps> = (props) => {
  const {
    titleTextKey = 'limitedAccessBlock.title',
    contentTextKey = 'limitedAccessBlock.description',
    actionButtonTextKey = 'limitedAccessBlock.continueBrowsing',
    onContinueBrowsing,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const title: ReactNode = (
    <Text
      type='Subheading'
      size='Medium'
      style='SemiBold'
      colour='BaseDark'
      align='Center'
      value={t(titleTextKey)}
    />
  );

  const content: ReactNode = (
    <Text
      size='Large'
      style='Regular'
      colour='BaseDark'
      align='Center'
      value={t(contentTextKey)}
    />
  );

  const actionButton: ReactNode = (
    <Button
      type='Text'
      style='Contained'
      size='Large'
      focusable={true}
      text={{
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        align: 'Center',
        value: t(actionButtonTextKey),
      }}
      onClick={onContinueBrowsing}
      className={styles.actionButton}
    />
  );

  return (
    <>
      <div className={cx(styles.limitedAccessBlock, className)}>
        <div className={styles.limitedAccessBlockContent}>
          {title}
          {content}
          {actionButton}
        </div>
      </div>
      <Footer theme='light' />
    </>
  );
};
