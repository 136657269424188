import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getCurrentHistoryState } from '../../../modules/navigation/Navigation.utils';
import Text, { type TextCombinedProps } from '../../atoms/Text';
import styles from './ListingCard.module.scss';
import { usePresenter } from './ListingCard.presenter';
import type { ListingCardProps } from './ListingCard.types';
import { ExclusiveBadge } from '../ExclusiveBadge';

const InternalListingCard: React.FC<ListingCardProps> = (props) => {
  const {
    isPayWithRewardsOnly,
    listingMetadata: {
      sectionName,
      row,
      ticketsTotal,
      formattedTicketsTotal,
      formattedCashPrice,
      formattedLoyaltyPrice,
      loyaltyUnitName,
    },
    listingHref,
    onListingCardClick,
    onListingCardTouchStart,
    onListingCardTouchEnd,
    onListingCardMouseEnter,
    onListingCardMouseLeave,
    className,
    listingCardExclusiveBadgeProps,
  } = usePresenter(props);

  const { t } = useTranslation();

  const largerTextProps: TextCombinedProps = {
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    className: styles.lineHeight18px,
  };

  const smallerTextProps: TextCombinedProps = {
    size: 'Small',
    style: 'Regular',
    colour: 'SubduedLight',
    className: styles.lineHeight16px,
  };

  const listingCardExclusiveBadge: ReactNode = listingCardExclusiveBadgeProps && (
    <ExclusiveBadge
      className={styles.cardholderExclusiveBadge}
      {...listingCardExclusiveBadgeProps}
    />
  );

  const leftContent: ReactNode = (<>
    {listingCardExclusiveBadge}
    <Text
      {...largerTextProps}
      value={sectionName}
    />
    <Text
      {...smallerTextProps}
      value={t('listingCard.row', { row })}
    />
    <Text
      {...smallerTextProps}
      value={t('listingCard.ticketsAvailable', { ticketsTotal: formattedTicketsTotal, count: ticketsTotal })}
    />
  </>);

  const eachText: ReactNode = (
    <Text
      {...smallerTextProps}
      value={t('listingCard.each')}
    />
  );

  const rightContent: ReactNode = isPayWithRewardsOnly
    ? (<>
      <div className={styles.rowSubContainer}>
        <Text
          {...largerTextProps}
          value={formattedLoyaltyPrice}
        />
        {!!loyaltyUnitName && (
          <Text
            {...smallerTextProps}
            value={loyaltyUnitName}
          />
        )}
      </div>
      {eachText}
    </>)
    : (<>
      <div className={styles.rowSubContainer}>
        <Text
          {...largerTextProps}
          value={formattedCashPrice}
        />
        <Text
          {...smallerTextProps}
          value={t('listingCard.or')}
        />
      </div>
      <Text
        {...smallerTextProps}
        value={`${formattedLoyaltyPrice} ${loyaltyUnitName}`.trim()}
      />
      {eachText}
    </>);

  return (<>
    <Link
      to={listingHref}
      state={getCurrentHistoryState()}
      onClick={onListingCardClick}
      onTouchStart={onListingCardTouchStart}
      onTouchEnd={onListingCardTouchEnd}
      onMouseEnter={onListingCardMouseEnter}
      onMouseLeave={onListingCardMouseLeave}
      className={cx(styles.listingCard, className)}
      data-testid='listing-card' // Expected by NonGuestListEventPage.module.scss. Do not change accidentally.
    >
      <div className={styles.contentContainer}>
        {leftContent}
      </div>
      <div className={styles.rightContentContainer}>
        {rightContent}
      </div>
    </Link>
  </>);
};

/** Memoize listing card since event pages render a large number of listing cards */
export const ListingCard = React.memo(InternalListingCard);
