import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const RISKIFIED_COOKIE_NAME = 'riskified-976';
const SESSION_ID_PREFIX = 'B2B-';
const generateSessionId = (): string => `${SESSION_ID_PREFIX}${uuidv4()}`;

function initRiskifiedPage() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  window.RISKX.go(`/checkout${window.location.search}`);
}

export const getBeaconSessionId = (): string => {
  const riskifiedCookie = Cookies.get(RISKIFIED_COOKIE_NAME);
  if (riskifiedCookie) {
    return riskifiedCookie;
  }
  const beaconSessionId = generateSessionId();
  const date = new Date();
  const expires = new Date(date.setDate(date.getDate() + 1));
  Cookies.set(RISKIFIED_COOKIE_NAME, beaconSessionId, { expires, path: '/' });

  return beaconSessionId;
};

export const removeRiskifiedCookie = (): void => {
  const riskifiedCookie = Cookies.get(RISKIFIED_COOKIE_NAME);
  if (riskifiedCookie) Cookies.remove(RISKIFIED_COOKIE_NAME);
};

const riskifiedBeaconLoad = (beaconSessionId: string) => {
  const storeDomain = 'securetix';
  const sessionId = beaconSessionId;
  const url = ('https:' == document.location.protocol ? 'https://' : 'http://')
    + 'beacon.riskified.com?shop=' + storeDomain + '&sid=' + sessionId;
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = url;
  s.onload = initRiskifiedPage;
  const x = document.getElementsByTagName('script')[0];
  x.parentNode?.insertBefore(s, x);
};

export const initRiskifiedBeacon = () => {
  const beaconSessionId = getBeaconSessionId();
  riskifiedBeaconLoad(beaconSessionId);
};
