import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CssVariableNameEnum } from '../../../lib/types';
import { useHtmlElementSize } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import { redirectToSignInURL } from '../../../modules/navigation/Navigation.utils';
import { TopNavContext } from '../../../modules/topNav';
import type { SignInDisclaimerPresenterProps, SignInDisclaimerProps } from './SignInDisclaimer.types';
import { checkIsValidPathForSignInDisclaimer } from './SignInDisclaimer.utils';

export const usePresenter = (props: SignInDisclaimerProps): SignInDisclaimerPresenterProps => {
  const { isSignedOut } = useContext(AuthContext);

  const { addTopFixedHeight } = useContext(TopNavContext);

  const { pathname } = useLocation();

  const isSignInDisclaimerShown: boolean = useMemo(() => isSignedOut && checkIsValidPathForSignInDisclaimer(pathname), [isSignedOut, pathname]);

  const signInDisclaimerRef = useRef<HTMLButtonElement>(null);

  const { height: signInDisclaimerHeight } = useHtmlElementSize({ htmlElement: signInDisclaimerRef.current });

  // Store SignInDisclaimer height.
  // Explanation: SignInDisclaimer is rendered with a fixed position which means it floats above the rest of the content.
  // To avoid layout issues (e.g. some content may get hidden behind the SignInDisclaimer) we also render a spacer div of the same height as the SignInDisclaimer.
  useEffect(() => {
    addTopFixedHeight(CssVariableNameEnum.signInDisclaimerHeight, signInDisclaimerHeight);
    return () => addTopFixedHeight(CssVariableNameEnum.signInDisclaimerHeight, 0);
  }, [signInDisclaimerHeight, addTopFixedHeight]);

  const onSignInDisclaimerClick = useCallback(() => redirectToSignInURL(), []);

  return {
    ...props,
    isSignInDisclaimerShown,
    signInDisclaimerRef,
    onSignInDisclaimerClick,
  };
};
