import { useContext, useEffect, useMemo } from 'react';
import { convertArrayToMap } from '../../../lib/objectUtils';
import { AuthContext } from '../../../modules/auth';
import { LoyaltyCurrenciesContext } from '../../../modules/loyaltyCurrencies';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { LoyaltyCurrency } from '../../../modules/partnership';
import { usePresetFilterStateFromUrl, type PresetFilterOption } from '../../organisms/PresetFilter';
import { CURRENT_REWARDS_PARAM, REWARD_TAGS_ID_MAP, REWARD_TAGS_MAP, REWARD_UNIT_MAP } from './RewardsSwitcher.constants';
import type { RewardsSwitcherPresenterProps, RewardsSwitcherProps, RewardTag } from './RewardsSwitcher.types';
import { getMatchingRewardsFromAccount, getRewardTags, getRewardTagsFilterOptions } from './RewardsSwitcher.utils';

export const usePresenter = (props: RewardsSwitcherProps): RewardsSwitcherPresenterProps => {
  const { eventMetadata } = props;

  const { tags } = eventMetadata ?? {};

  const { isSignedIn, account } = useContext(AuthContext);

  const { setSelectedLoyaltyCurrency, loyaltyCurrencies } = useContext(LoyaltyCurrenciesContext);

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  /** Memoized reward tags from event metadata */
  const rewardTags: RewardTag[] | undefined = useMemo(() => {
    if (isSignedIn && account && getMatchingRewardsFromAccount) {
      return getMatchingRewardsFromAccount({ account, tags });
    } else {
      return getRewardTags(tags);
    }
  }, [isSignedIn, account, tags]);

  /** The first reward tag is the default reward tag */
  const defaultRewardTag: RewardTag | undefined = useMemo(() => {
    if (isSignedIn && account && !rewardTags?.length) {
      return REWARD_UNIT_MAP[`${account.loyalty_program.loyalty_unit_name}`];
    } else {
      return rewardTags?.length ? rewardTags[0] : undefined;
    }
  }, [isSignedIn, account, rewardTags]);

  /** Default preset filter option based on the default reward tag */
  const defaultRewardsUnitFilterOption: PresetFilterOption | undefined = useMemo(
    () => defaultRewardTag
      ? {
        id: REWARD_TAGS_ID_MAP[defaultRewardTag] || '',
        name: REWARD_TAGS_MAP[defaultRewardTag] || '',
      }
      : undefined,
    [defaultRewardTag],
  );

  /** Determines if the Rewards Switcher should be shown */
  const shouldShowRewardsSwitcher: boolean = useMemo(() => !!rewardTags && rewardTags.length > 1, [rewardTags]) ;

  // Reward unit filter options for the preset filter
  const rewardsUnitFilterOptions: PresetFilterOption[] = useMemo(
    () => shouldShowRewardsSwitcher ? getRewardTagsFilterOptions({ rewardTags }) : [],
    [shouldShowRewardsSwitcher, rewardTags],
  );

  /** Converts filter options to a map if rewards switcher is visible */
  const rewardsUnitFilterOptionsMap: Record<string, PresetFilterOption> | undefined = useMemo(
    () => rewardsUnitFilterOptions.length
      ? convertArrayToMap(rewardsUnitFilterOptions)
      : undefined,
    [rewardsUnitFilterOptions],
  );

  /** Retrieves the selected reward filter state from the URL */
  const rewardsUnitFilterState: PresetFilterOption | undefined = usePresetFilterStateFromUrl({
    queryParamName: CURRENT_REWARDS_PARAM,
    presetFilterOptionsMap: rewardsUnitFilterOptionsMap,
  });

  // Append default value to the URL if no filter is set
  useEffect(() => {
    if (shouldShowRewardsSwitcher) {
      if (defaultRewardsUnitFilterOption && !rewardsUnitFilterState) {
        appendQueryParamsToUrl({ [CURRENT_REWARDS_PARAM]: defaultRewardsUnitFilterOption.id.toString() });
      }
    } else {
      appendQueryParamsToUrl({ [CURRENT_REWARDS_PARAM]: '' });
    }
  }, [shouldShowRewardsSwitcher, defaultRewardsUnitFilterOption, rewardsUnitFilterState, appendQueryParamsToUrl]);

  // Update loyalty currencies context value when URL query param changes
  useEffect(() => {
    if (rewardsUnitFilterState && loyaltyCurrencies) {
      const selectedLoyaltyCurrency: LoyaltyCurrency | undefined = Object.values(loyaltyCurrencies).find(({ id }) => id === rewardsUnitFilterState.id);
      if (selectedLoyaltyCurrency) {
        setSelectedLoyaltyCurrency(selectedLoyaltyCurrency);
      }
    }
  }, [rewardsUnitFilterState, loyaltyCurrencies, setSelectedLoyaltyCurrency]);

  return {
    ...props,
    shouldShowRewardsSwitcher,
    rewardsUnitFilterOptions,
    rewardsUnitFilterState,
  };
};
