import React, { createContext, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import type { RegionFilterState } from '../../components/organisms/RegionFilter';
import { convertArrayToMap } from '../../lib/objectUtils';
import { useAnalyticsManager } from '../analytics/useAnalyticsManager';
import { AuthContext } from '../auth';
import type { Region } from '../partnership';
import { getRegions, getRegionsByIp } from '../partnership/api';
import { checkAreRegionsLoading, getCurrentRegion, getRegionContextError, getSortedRegions } from './RegionContext.utils';

export type RegionContextValue = {
  areRegionsLoading: boolean;
  allRegions: Region[];
  allRegionsMap: Record<string, Region> | undefined;
  currentRegion: Region | undefined;
  setPerformerId: (newPerformerId: number | undefined) => void;
  areRegionsByPerformerIdLoading: boolean;
  regionsByPerformerId: Region[];
  regionsByPerformerIdMap: Record<string, Region> | undefined;
  homePageRegionFilterState: RegionFilterState;
  setHomePageRegionFilterState: (newHomePageRegionFilterState: RegionFilterState) => void;
  error: Error | undefined;
};

const initialContext: RegionContextValue = {
  areRegionsLoading: false,
  allRegions: [],
  allRegionsMap: {},
  currentRegion: undefined,
  setPerformerId: () => { },
  areRegionsByPerformerIdLoading: false,
  regionsByPerformerId: [],
  regionsByPerformerIdMap: {},
  homePageRegionFilterState: 'currentRegion',
  setHomePageRegionFilterState: () => { },
  error: undefined,
};

export const RegionContext = createContext<RegionContextValue>(initialContext);

export const RegionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAccountLoading } = useContext(AuthContext);

  const { onError } = useAnalyticsManager();

  const isRegionsFetchEnabled: boolean = useMemo(() => !isAccountLoading, [isAccountLoading]);

  const { data: unsortedAllRegions = [], isLoading: areApiAllRegionsLoading, error: allRegionsError } = useQuery(
    'getRegions',
    () => getRegions(),
    { enabled: isRegionsFetchEnabled, onError },
  );

  const areAllRegionsLoading: boolean = useMemo(() => !isRegionsFetchEnabled || areApiAllRegionsLoading, [isRegionsFetchEnabled, areApiAllRegionsLoading]);

  const allRegions: Region[] = useMemo(
    () => getSortedRegions({ areRegionsLoading: areAllRegionsLoading, unsortedRegions: unsortedAllRegions }),
    [areAllRegionsLoading, unsortedAllRegions],
  );

  // Map of regions where key is region.id and value is region.
  // This is required to optimise search for a region by region.id.
  const allRegionsMap: Record<string, Region> | undefined = useMemo(
    () => !areAllRegionsLoading ? convertArrayToMap(allRegions) : undefined,
    [areAllRegionsLoading, allRegions],
  );

  const { data: regionsByIp = [], isLoading: areApiRegionsByIpLoading, error: regionsByIpError } = useQuery(
    'getRegionsByIp',
    () => getRegionsByIp(),
    { enabled: isRegionsFetchEnabled, onError },
  );

  const areRegionsByIpLoading: boolean = useMemo(() => !isRegionsFetchEnabled || areApiRegionsByIpLoading, [isRegionsFetchEnabled, areApiRegionsByIpLoading]);

  const currentRegion: Region | undefined = useMemo(
    () => getCurrentRegion({ areRegionsByIpLoading, regionsByIp }),
    [areRegionsByIpLoading, regionsByIp],
  );

  const [performerId, setPerformerId] = useState<number | undefined>();

  const isRegionsByPerformerIdFetchEnabled: boolean = useMemo(() => isRegionsFetchEnabled && !!performerId, [isRegionsFetchEnabled, performerId]);

  const { data: unsortedRegionsByPerformerId = [], isLoading: areApiRegionsByPerformerIdLoading, error: regionsByPerformerIdError } = useQuery(
    ['getRegionsByPerformerId', performerId],
    () => getRegions({ performer_id: performerId }),
    { enabled: isRegionsByPerformerIdFetchEnabled, onError },
  );

  const areRegionsByPerformerIdLoading: boolean = useMemo(() => !isRegionsByPerformerIdFetchEnabled || areApiRegionsByPerformerIdLoading, [isRegionsByPerformerIdFetchEnabled, areApiRegionsByPerformerIdLoading]);

  const regionsByPerformerId: Region[] = useMemo(
    () => getSortedRegions({ areRegionsLoading: areRegionsByPerformerIdLoading, unsortedRegions: unsortedRegionsByPerformerId }),
    [areRegionsByPerformerIdLoading, unsortedRegionsByPerformerId],
  );

  // Map of performer regions where key is region.id and value is region.
  // This is required to optimise search for a region by region.id.
  const regionsByPerformerIdMap: Record<string, Region> | undefined = useMemo(
    () => !areRegionsByPerformerIdLoading ? convertArrayToMap(regionsByPerformerId) : undefined,
    [areRegionsByPerformerIdLoading, regionsByPerformerId],
  );

  const [homePageRegionFilterState, setHomePageRegionFilterState] = useState<RegionFilterState>('currentRegion');

  const areRegionsLoading: boolean = useMemo(
    () => checkAreRegionsLoading({ areAllRegionsLoading, allRegionsLength: allRegions.length, areRegionsByIpLoading }),
    [areAllRegionsLoading, allRegions.length, areRegionsByIpLoading],
  );

  const error: Error | undefined = useMemo(
    () => getRegionContextError({ allRegionsError, regionsByIpError, regionsByPerformerIdError }),
    [allRegionsError, regionsByIpError, regionsByPerformerIdError],
  );

  const value: RegionContextValue = useMemo(() => ({
    areRegionsLoading,
    allRegions,
    allRegionsMap,
    currentRegion,
    setPerformerId,
    areRegionsByPerformerIdLoading,
    regionsByPerformerId,
    regionsByPerformerIdMap,
    homePageRegionFilterState,
    setHomePageRegionFilterState,
    error,
  }), [
    areRegionsLoading,
    allRegions,
    allRegionsMap,
    currentRegion,
    areRegionsByPerformerIdLoading,
    regionsByPerformerId,
    regionsByPerformerIdMap,
    homePageRegionFilterState,
    error,
  ]);

  return (
    <RegionContext.Provider value={value}>
      {children}
    </RegionContext.Provider>
  );
};
