import React, { createContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAnalyticsManager } from '../analytics/useAnalyticsManager';
import { getCategories } from '../partnership/api';
import { initialCategoriesContextValue } from './CategoriesContext.constants';
import type { CategoriesContextValue } from './CategoriesContext.types';
import { getCategoriesContextValue } from './CategoriesContext.utils';

export const CategoriesContext = createContext<CategoriesContextValue>(initialCategoriesContextValue);

export const CategoriesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { onError } = useAnalyticsManager();

  const { data: categoriesResponse, isLoading: areCategoriesLoading, error } = useQuery(
    'getCategories',
    () => getCategories({}),
    { onError },
  );

  const value: CategoriesContextValue = useMemo(() => getCategoriesContextValue({
    categoriesResponse,
    areCategoriesLoading,
    error,
  }), [categoriesResponse, areCategoriesLoading, error]);

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
};
