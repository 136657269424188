import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseFilter } from '../../atoms/BaseFilter';
import { usePresenter } from './PresetFilter.presenter';
import type { PresetFilterOption, PresetFilterProps } from './PresetFilter.types';
import { getDisplayValue } from './PresetFilter.utils';

export const PresetFilter: React.FC<PresetFilterProps> = (props) => {
  const {
    presetFilterState,
    placeholderKey,
    filterOptions,
    onFilterOptionSelected,
    isClearable = true,
    isDisabled = false,
    isHighlightOnExpandOnly = false,
    theme = 'light',
    className,
    classes,
    ariaLabel,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <BaseFilter<PresetFilterOption, PresetFilterOption>
      filterState={presetFilterState}
      getDisplayValue={getDisplayValue}
      placeholder={t(placeholderKey)}
      filterOptions={filterOptions}
      onFilterOptionSelected={onFilterOptionSelected}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isHighlightOnExpandOnly={isHighlightOnExpandOnly}
      theme={theme}
      className={className}
      classes={classes}
      ariaLabel={ariaLabel}
    />
  );
};
