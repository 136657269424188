import cx from 'classnames';
import React from 'react';
import Icon from '../../atoms/Icon';
import { RadioButtonIconPropsByTheme } from './RadioButton.constants';
import styles from './RadioButton.module.scss';
import { usePresenter } from './RadioButton.presenter';
import type { RadioButtonProps } from './RadioButton.types';

export const RadioButton = <TValue extends (number | string) = string>(props: RadioButtonProps<TValue>) => {
  const {
    children,
    isSelected,
    isDisabled,
    onClick,
    onKeyDown,
    theme,
    ariaLabel,
    className,
  } = usePresenter(props);

  const radioButtonIcon: React.ReactNode = (
    <Icon
      {...RadioButtonIconPropsByTheme[`${theme}${isSelected ? 'Selected' : 'Unselected'}`]}
      className={cx(styles.radioButtonIcon, { [styles.disabledIcon]: isDisabled })}
    />
  );

  return (
    <div
      tabIndex={isDisabled ? undefined : 0} // Disable keyboard focus if disabled
      role={isDisabled ? undefined : 'radio'}
      aria-label={isDisabled ? undefined : ariaLabel}
      aria-checked={isDisabled ? undefined : isSelected}
      onClick={isDisabled ? undefined : onClick}
      onKeyDown={isDisabled ? undefined : onKeyDown} // Disable interaction if disabled
      className={cx(styles.radioButton, className, { [styles.disabled]: isDisabled })}
    >
      <div
        className={cx(styles.radioButtonLabel, isDisabled && styles[`${theme}Disabled`])}
      >
        {children}
      </div>
      {radioButtonIcon}
    </div>
  );
};
