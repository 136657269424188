import React from 'react';
import { Modal } from 'react-bootstrap';
import { OnBoardingModalContent } from '../../molecules/OnBoardingModalContent';
import styles from './OnBoardingModal.module.scss';
import { usePresenter } from './OnBoardingModal.presenter';
import type { OnBoardingModalProps } from './OnBoardingModal.types';

export const OnBoardingModal: React.FC<OnBoardingModalProps> = (props) => {
  const {
    isOnBoardingModalOpen,
    closeOnBoardingModal,
  } = usePresenter(props);

  return (
    <Modal
      show={isOnBoardingModalOpen}
      autoFocus={false}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
    >
      <OnBoardingModalContent
        isOnBoardingModalOpen={isOnBoardingModalOpen}
        closeOnBoardingModal={closeOnBoardingModal}
      />
    </Modal>
  );
};
