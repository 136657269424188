import { useCallback, useContext } from 'react';
import { AuthContext } from '../../../modules/auth';
import { redirectToSignInURL } from '../../../modules/navigation/Navigation.utils';
import type { SignInDialogPresenterProps, SignInDialogProps } from './SignInDialog.types';

export const usePresenter = (props: SignInDialogProps): SignInDialogPresenterProps => {
  const { isSignedOut } = useContext(AuthContext);

  const onSignInDialogClick = useCallback(() => redirectToSignInURL(), []);

  return {
    ...props,
    isSignInDialogShown: isSignedOut,
    onSignInDialogClick,
  };
};
