import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './modules/auth';
import { CategoriesProvider } from './modules/categories';
import { LocaleProvider } from './modules/locale';
import { LoyaltyCurrenciesProvider } from './modules/loyaltyCurrencies';
import { RegionProvider } from './modules/region';
import { UserIdleProvider } from './modules/userIdle';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 60 * 1000, // 10 mins
      retry: false,
    },
  },
});

const AppProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <LocaleProvider>
    <QueryClientProvider client={queryClient}>
      <UserIdleProvider>
        <LoyaltyCurrenciesProvider>
          <AuthProvider>
            <RegionProvider>
              <CategoriesProvider>
                {children}
              </CategoriesProvider>
            </RegionProvider>
          </AuthProvider>
        </LoyaltyCurrenciesProvider>
      </UserIdleProvider>
    </QueryClientProvider>
  </LocaleProvider>
);

export default AppProvider;
