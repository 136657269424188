import type { CategoriesPageResponse } from '../partnership';

/**
 * Creates a context value for categories based on the provided response, loading state, and error.
 * @param {CategoriesPageResponse | undefined} categoriesResponse - The response containing categories data.
 * @param {boolean} areCategoriesLoading - A boolean indicating whether the categories are currently loading.
 * @param {unknown} error - An error object if there was an error loading the categories.
 * @returns {{categories: Array, areCategoriesLoading: boolean, error: Error | undefined}} An object containing the categories, loading state, and error.
 */
export const getCategoriesContextValue = (params: {
  categoriesResponse: CategoriesPageResponse | undefined,
  areCategoriesLoading: boolean,
  error: unknown,
}) => {
  const { categoriesResponse, areCategoriesLoading, error } = params;
  return {
    categories: categoriesResponse?.items ?? [],
    areCategoriesLoading: areCategoriesLoading || !categoriesResponse?.items.length,
    error: error as Error | undefined,
  };
};
