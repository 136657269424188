import React, { createContext, useCallback, useEffect, useState } from 'react';
import { URLs } from '../../lib/constants';
import type { Props } from '../../lib/types';
import { startTimer, stopTimer } from './sessionTimeout';

export type UserIdleContextValue = {
  showWarning?: boolean,
  closeWarning: () => void;
  startSession: () => void;
  stopSession: () => void;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialContext: UserIdleContextValue = {
  showWarning: false,
  closeWarning: () => { },
  startSession: () => { },
  stopSession: () => { },
  setIsAuthenticated: () => { },
};

export const UserIdleContext = createContext<UserIdleContextValue>(initialContext);

export const UserIdleProvider: React.FC<Props> = ({ children }) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const closeWarning = useCallback(() => {
    setShowWarning(false);
  }, []);

  const onSessionWarning = useCallback(() => {
    setShowWarning(true);
  }, []);

  const onSessionLogout = useCallback(() => {
    window.location.assign(URLs.Logout);
  }, []);

  useEffect(() => {
    if (showWarning) {
      const logout = setTimeout(() => {
        onSessionLogout();
      }, 5 * 60 * 1000);
      return () => clearTimeout(logout);
    }
  }, [showWarning, onSessionLogout]);

  const startSession = useCallback(() => {
    startTimer(onSessionWarning, onSessionLogout);
  }, [onSessionWarning, onSessionLogout]);

  const stopSession = useCallback(() => {
    stopTimer();
    setShowWarning(false);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      startSession();
    } else {
      stopSession();
    }
  }, [isAuthenticated, startSession, stopSession]);

  return (
    <UserIdleContext.Provider value={{
      showWarning,
      closeWarning,
      startSession,
      stopSession,
      setIsAuthenticated,
    }}>
      {children}
    </UserIdleContext.Provider>
  );
};
