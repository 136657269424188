import { SUPPORTED_PROGRAM_TYPE_TAGS } from '../../../lib/constants';
import { isAccountCardValidForProvidedTags } from '../../../lib/eventUtils';
import type { AccountCardDetail } from '../../../modules/auth/types';
import type { AccountCardProps } from '../AccountCard';
import { AccountCardThemesByRewardsBalanceTheme } from './RewardsBalance.constants';
import type { RewardsBalanceTheme } from './RewardsBalance.types';

// TODO: ND : Update existing unit tests
/**
 * Builds an array of account card props.
 * @returns {AccountCardProps[]} Array of account card props.
 */
export const getAccountCardProps = (params: {
  /** Account card details */
  accountCardDetails: AccountCardDetail[];
  theme: RewardsBalanceTheme;
  tags: string[] | undefined;
  isExclusiveEvent: boolean;
}): AccountCardProps[] => {
  const { accountCardDetails, theme: rewardsBalanceTheme, tags, isExclusiveEvent } = params;
  // Get only program type tags which is supported for listing
  const listingProgramTypeTags = tags && tags.filter((tag: string) => SUPPORTED_PROGRAM_TYPE_TAGS[tag]) || [];

  return accountCardDetails.map((accountCardDetail: AccountCardDetail, key: number) => ({
    ...accountCardDetail,
    key,
    theme: AccountCardThemesByRewardsBalanceTheme[rewardsBalanceTheme],
    areRewardUnitsBold: true,
    isDisabled: isExclusiveEvent && !isAccountCardValidForProvidedTags(accountCardDetail, listingProgramTypeTags),
  }));
};
