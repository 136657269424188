import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLs } from '../../../lib/constants';
import { generateTagFilterForSearch } from '../../../lib/util';
import { trackPageViewEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { EventListContext } from '../../../modules/eventList';
import { usePreserveHistoryState } from '../../../modules/navigation/Navigation.hooks';
import type { SearchQueryParams } from '../../../modules/partnership';
import { RegionContext } from '../../../modules/region';
import { convertDateFilterStateToDateRange, useDateFilterStateFromUrl, type DateFilterState, type DateRange } from '../../organisms/DateFilter';
import { buildAllEventsPageUrlWithParams } from '../AllEventsPage';
import { TRACKING_PAGE_NAME } from './SearchResultsPage.constants';
import { useSearchQueryFromUrl } from './SearchResultsPage.hooks';
import type { SearchResultsPagePresenterProps, SearchResultsPageProps } from './SearchResultsPage.types';
import { buildSearchResultsPageStaticApiParams } from './SearchResultsPage.utils';

export const usePresenter = (props: SearchResultsPageProps): SearchResultsPagePresenterProps => {
  const { isAccountLoading, accountTags } = useContext(AuthContext);

  const apiTagFilter: string | undefined = useMemo(() => generateTagFilterForSearch({ accountTags }), [accountTags]);

  const { setIsFetchEnabled, setStaticApiParams, eventsTotal } = useContext(EventListContext);

  const { homePageRegionFilterState } = useContext(RegionContext);

  const { trackEvent } = useAnalyticsManager();

  // Track analytics for page visits
  useEffect(() => {
    trackPageViewEvent(trackEvent, TRACKING_PAGE_NAME.forPageVisits);
  }, [trackEvent]);

  // Track analytics for search requests
  const trackSearchAnalytics = useCallback((apiParams: SearchQueryParams) => {
    trackEvent('search', { search_term: apiParams.q });
  }, [trackEvent]);

  const navigate = useNavigate();

  /** Search query that is extracted and decoded from query parameters in the current URL */
  const searchQuery: string = useSearchQueryFromUrl();

  // Navigate to Home page if search query is not provided in the URL
  // This is because search query is mandatory for Search Results page
  useEffect(() => {
    if (!searchQuery) {
      navigate(URLs.HomePage, { replace: true });
    }
  });

  usePreserveHistoryState({ isDisabled: !searchQuery });

  /** Date filter state that is extracted from query parameters in the current URL */
  const dateFilterState: DateFilterState | undefined = useDateFilterStateFromUrl();
  const dateRange: Record<keyof DateRange, string> = useMemo(() => convertDateFilterStateToDateRange(dateFilterState), [dateFilterState]);

  // Update Event List context with isFetchEnabled flag
  useEffect(
    () => setIsFetchEnabled(!isAccountLoading),
    [isAccountLoading, setIsFetchEnabled],
  );

  // Update Event List context with static API parameters that will be merged with dynamic page index
  useEffect(() => {
    setStaticApiParams(buildSearchResultsPageStaticApiParams({
      searchQuery,
      dateRange,
      apiTagFilter,
    }));
  }, [searchQuery, dateRange, apiTagFilter, setStaticApiParams]);

  /** Link to All Events page with location filter */
  const allEventsPageLinkHref: string | undefined = useMemo(
    () => buildAllEventsPageUrlWithParams({ regionFilterState: homePageRegionFilterState }),
    [homePageRegionFilterState],
  );

  return {
    ...props,
    eventsTotal,
    searchQuery,
    dateFilterState,
    trackSearchAnalytics,
    allEventsPageLinkHref,
  };
};
