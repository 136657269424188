import type { LoyaltyCurrenciesContextValue } from './LoyaltyCurrenciesContext.types';

export const initialLoyaltyCurrenciesContextValue = {
  loyaltyCurrencies: {},
  areLoyaltyCurrenciesLoading: false,
  error: undefined,
  selectedLoyaltyCurrency: {
    id: 'miles',
    rate: 0.008,
    display_name: 'miles',
  },
  setSelectedLoyaltyCurrency: () => { },
} as const satisfies LoyaltyCurrenciesContextValue;
