import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../atoms/Text';
import { PresetFilter } from '../../organisms/PresetFilter';
import { CURRENT_REWARDS_PARAM, RewardsSwitcherTextColorsByTheme } from './RewardsSwitcher.constants';
import styles from './RewardsSwitcher.module.scss';
import { usePresenter } from './RewardsSwitcher.presenter';
import type { RewardsSwitcherProps } from './RewardsSwitcher.types';

export const RewardsSwitcher: React.FC<RewardsSwitcherProps> = (props) => {
  const {
    shouldShowRewardsSwitcher,
    rewardsUnitFilterOptions,
    rewardsUnitFilterState,
    theme,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  if (!shouldShowRewardsSwitcher) {
    return null;
  }

  return (
    <div className={cx(styles.rewardsSwitcherContainer, className)}>
      <Text
        size='Small'
        style='SemiBold'
        colour={RewardsSwitcherTextColorsByTheme[theme]}
        value={t('rewardsUnitSwitcher.title')}
      />
      <PresetFilter
        queryParamName={CURRENT_REWARDS_PARAM}
        presetFilterState={rewardsUnitFilterState}
        presetFilterOptions={rewardsUnitFilterOptions}
        placeholderKey=''
        isClearable={false}
        isHighlightOnExpandOnly={true}
        theme={theme}
        className={styles.rewardsSwitcher}
      />
    </div>
  );
};
