import { AccountCardFallbackImageUrlsByTheme } from './AccountCard.constants';
import type { AccountCardTheme } from './AccountCard.types';

/** Gets a trimmed account card image URL if it is not empty, otherwise gets a fallback URL based on the theme */
export const getAccountCardImageUrl = (params: {
  accountCardImageUrl: string | undefined;
  theme: AccountCardTheme;
}): string => {
  const { accountCardImageUrl, theme } = params;

  const trimmedAccountCardImageUrl: string | undefined = accountCardImageUrl?.trim();

  return trimmedAccountCardImageUrl || AccountCardFallbackImageUrlsByTheme[theme] as string;
};
