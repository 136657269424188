import { addTagFilterToEventQueryParams } from '../../../lib/util';
import type { AccountTags } from '../../../modules/auth/types';
import type { EventsQueryParams } from '../../../modules/partnership';
import type { DateRange } from '../../organisms/DateFilter';
import type { PresetFilterOption } from '../../organisms/PresetFilter';
import { CATEGORIES_MAP } from './VenuePage.constants';
import type { VenueCategory } from './VenuePage.types';

/**
 * Returns true if either venueId is not a valid positive number or API request to fetch venue details failed.
 * @returns {boolean} True if either venueId is not a valid positive number or API request to fetch venue details failed.
 */
export const checkIsVenueInvalid = (params: {
  /** Venue Id */
  venueId: number | undefined;
  /** True if API request to fetch venue details failed */
  isVenueError: boolean;
}): boolean => {
  const { venueId, isVenueError } = params;
  return !venueId || venueId < 0 || isVenueError;
};

/**
 * Builds query parameters for fetching events based on the provided filters.
 * @returns {EventsQueryParams} Query parameters for fetching events.
 */
export const buildVenuePageStaticApiParams = (params: {
  /** Venue Id */
  venueId: number | undefined;
  /** Object that contains loyalty, program, and processing network tags for the account */
  accountTags: AccountTags;
  /** Category filter state for filtering events */
  categoryFilterState: PresetFilterOption<VenueCategory> | undefined;
  /** Object containing the start and end dates for filtering events */
  dateRange: Record<keyof DateRange, string>;
}): EventsQueryParams => {
  const {
    venueId,
    accountTags,
    categoryFilterState,
    dateRange,
  } = params;

  if (!venueId) {
    return {};
  }

  const categoryDetails = categoryFilterState ? CATEGORIES_MAP[categoryFilterState.id] : undefined;

  return addTagFilterToEventQueryParams({
    eventQueryParams: {
      venue_id: venueId,
      ...categoryDetails?.apiParams,
      ...(dateRange.startDate ? { date_start: dateRange.startDate } : {}),
      ...(dateRange.endDate ? { date_end: dateRange.endDate } : {}),
    },
    accountTags,
    includeSecondaryTagFilter: categoryDetails?.includeSecondaryRagFilter ?? true, // Add 'NOT C1_EXCLUSIVE' secondary tag filter for non-exclusive category
  });
};
