import { useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CATEGORY_PARAM } from '../../../lib/constants';
import { parseNumber } from '../../../lib/util';
import { trackPageViewEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { EventListContext } from '../../../modules/eventList';
import { getVenue } from '../../../modules/partnership/api';
import { convertDateFilterStateToDateRange, useDateFilterStateFromUrl, type DateFilterState, type DateRange } from '../../organisms/DateFilter';
import { usePresetFilterStateFromUrl, type PresetFilterOption } from '../../organisms/PresetFilter';
import { CATEGORIES_MAP, CategoryFilterOptions, TRACKING_PAGE_NAME } from './VenuePage.constants';
import type { VenueCategory, VenuePagePresenterProps, VenuePageProps } from './VenuePage.types';
import { buildVenuePageStaticApiParams, checkIsVenueInvalid } from './VenuePage.utils';

export const usePresenter = (props: VenuePageProps): VenuePagePresenterProps => {
  const { setTopDisclaimer } = props;

  const { accountTags } = useContext(AuthContext);

  const { setIsFetchEnabled, setStaticApiParams } = useContext(EventListContext);

  const { trackEvent, onError } = useAnalyticsManager();

  const { venueId: venueIdStr } = useParams<{ venueId: string; }>();

  /** Validated venue Id. Undefined if venue Id is invalid, e.g. when it is non-numeric or it is less than 1. */
  const venueId: number | undefined = useMemo(() => parseNumber(venueIdStr, { minValue: 1 }), [venueIdStr]);

  const { data: venue, isError: isVenueError } = useQuery(
    ['getVenue', venueId],
    () => getVenue(`${venueId}`),
    { enabled: !!venueId, onError, retry: false },
  );

  const isVenueInvalid: boolean = useMemo(
    () => checkIsVenueInvalid({ venueId, isVenueError }),
    [venueId, isVenueError],
  );

  // Track analytics for page visits
  useEffect(() => {
    if (venue) {
      trackPageViewEvent(
        trackEvent,
        TRACKING_PAGE_NAME.forPageVisits,
        {
          venue_id: venue.id,
          venue: venue.name,
          venue_city: venue.city,
          venue_state: venue.state,
        },
      );

      trackEvent('view_item_list', {
        ecommerce: {
          item_list_name: TRACKING_PAGE_NAME.forViewItemList,
        },
      });
    }
  }, [venue, trackEvent]);

  // Set top disclaimer message based on the venue details
  useEffect(() => {
    setTopDisclaimer(venue?.disclaimer);

    // Cleanup on component unmount
    return () => {
      setTopDisclaimer(undefined);
    };
  }, [venue?.disclaimer, setTopDisclaimer]);

  /** Category filter state that is extracted from query parameters in the current URL */
  const categoryFilterState: PresetFilterOption<VenueCategory> | undefined = usePresetFilterStateFromUrl({
    queryParamName: CATEGORY_PARAM,
    presetFilterOptionsMap: CATEGORIES_MAP,
  });

  /** Date filter state that is extracted from query parameters in the current URL */
  const dateFilterState: DateFilterState | undefined = useDateFilterStateFromUrl();
  const dateRange: Record<keyof DateRange, string> = useMemo(() => convertDateFilterStateToDateRange(dateFilterState), [dateFilterState]);

  // Update Event List context with isFetchEnabled flag
  useEffect(
    () => setIsFetchEnabled(!!venue),
    [venue, setIsFetchEnabled],
  );

  // Update Event List context with static API parameters that will be merged with dynamic page index
  useEffect(() => {
    setStaticApiParams(buildVenuePageStaticApiParams({
      venueId,
      accountTags,
      categoryFilterState,
      dateRange,
    }));
  }, [venueId, accountTags, categoryFilterState, dateRange, setStaticApiParams]);

  return {
    ...props,
    isVenueInvalid,
    venue,
    categoryFilterOptions: CategoryFilterOptions,
    categoryFilterState,
    dateFilterState,
  };
};
