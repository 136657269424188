import cx from 'classnames';
import type { FC } from 'react';
import { EXCLUSIVE_CATEGORY, TAYLOR_SWIFT_PERFORMER_ID, URLs } from '../../../lib/constants';
import { addTagFilterToEventQueryParams, formatDateToApiDate } from '../../../lib/util';
import type { AccountTags } from '../../../modules/auth/types';
import { getTodaysDate } from '../../../modules/date/utils';
import type { Event, EventsQueryParams, Performer, PerformersQueryParams } from '../../../modules/partnership';
import { matchesTaylorSwift } from '../../../modules/partnership/utils';
import type { EventCardProps } from '../../molecules/EventCard';
import type { PerformerCardProps } from '../../molecules/PerformerCard';
import type { CarouselSectionProps } from '../../organisms/CarouselSection';
import type { RegionFilterState } from '../../organisms/RegionFilter';
import { buildAllEventsPageUrlWithParams, type AllEventsTabKey } from '../AllEventsPage';
import { CARDHOLDER_EXCLUSIVE_EVENTS_PAGE_SIZE, CommonCarouselSectionProps, DEFAULT_PAGE, DEFAULT_PAGE_SIZE, StaticCarouselSectionPropsByType, TRACKING_PAGE_NAME } from './HomePage.constants';
import styles from './HomePage.module.scss';

/**
 * Returns a link to All Events page if there are more than minItems items.
 * @returns Link to All Events page if there are more than minItems items. Otherwise, undefined.
 */
export const getAllEventsLinkHref = (params: {
  /** Number of items */
  itemsTotal: number | undefined;
  /** Minimum number of items to render View All / View Events link */
  minItems: number;
  /** Tab key of All Events page */
  allEventsTabKey: AllEventsTabKey | undefined;
  /** Region filter state */
  regionFilterState: RegionFilterState;
}): string | undefined => {
  const { itemsTotal, minItems, allEventsTabKey, regionFilterState } = params;

  return itemsTotal && itemsTotal > minItems
    ? buildAllEventsPageUrlWithParams({ tabKey: allEventsTabKey, regionFilterState })
    : undefined;
};

/**
 * Builds API query parameters to fetch cardholder exclusive events.
 * @returns {EventsQueryParams | undefined} API query parameters to fetch cardholder exclusive events. Undefined if account is not provided.
 */
export const buildCardholderExclusiveEventsQueryParams = (params: {
  /** Object that contains loyalty, program, and processing network tags for the account */
  accountTags: AccountTags;
}): EventsQueryParams | undefined => {
  const { accountTags } = params;

  return addTagFilterToEventQueryParams({
    eventQueryParams: {
      page: DEFAULT_PAGE,
      per_page: CARDHOLDER_EXCLUSIVE_EVENTS_PAGE_SIZE,
      category: EXCLUSIVE_CATEGORY,
    },
    accountTags,
    shouldExcludeCollapsedEvents: true, // Exclude collapsed events
  });
};

/**
 * Returns a link to Exclusive Events page if there are more than 4 cardholder exclusive events
 * @returns Link to Exclusive Events page if there are more than 4 cardholder exclusive events. Otherwise, undefined.
 */
export const getCardholderExclusiveEventsLinkHref = (params: {
  /** Number of cardholder exclusive events */
  cardholderExclusiveEventsTotal: number | undefined;
  /** Minimum number of cardholder exclusive events to render View All link */
  minCardholderExclusiveEvents: number;
}): string | undefined => {
  const { cardholderExclusiveEventsTotal, minCardholderExclusiveEvents } = params;

  return cardholderExclusiveEventsTotal && cardholderExclusiveEventsTotal > minCardholderExclusiveEvents
    ? URLs.ExclusiveEventsPage
    : undefined;
};

/**
 * Builds API query parameters to fetch upcoming events.
 * @returns {EventsQueryParams | undefined} API query parameters to fetch upcoming events. Undefined if required data is still loading.
 */
export const buildUpcomingEventsQueryParams = (params: {
  /** Indicates if required data is still loading */
  isRequiredDataLoading: boolean;
  /** Id of the currently selected region */
  regionId: number | undefined;
}): EventsQueryParams | undefined => {
  const { isRequiredDataLoading, regionId } = params;

  if (isRequiredDataLoading) {
    return undefined;
  }

  return {
    page: DEFAULT_PAGE,
    per_page: DEFAULT_PAGE_SIZE,
    ...(regionId ? { region_id: regionId } : {}),
    date_start: formatDateToApiDate(getTodaysDate(), '2-digit'),
  };
};

/**
 * Builds API query parameters to fetch top performers.
 * @returns {PerformersQueryParams | undefined} API query parameters to fetch top performers. Undefined if required data is still loading.
 */
export const buildPerformersQueryParams = (params: {
  /** Indicates if required data is still loading */
  isRequiredDataLoading: boolean;
  /** Id of the currently selected region */
  regionId: number | undefined;
  /** Additional API query parameters */
  additionalQueryParams: PerformersQueryParams;
}): PerformersQueryParams | undefined => {
  const { isRequiredDataLoading, regionId, additionalQueryParams } = params;

  if (isRequiredDataLoading) {
    return undefined;
  }

  return {
    page: DEFAULT_PAGE,
    // Request 1 more performer to check if we need to render View Events link
    pageSize: DEFAULT_PAGE_SIZE + 1,
    ...(regionId ? { regionId } : {}),
    ...additionalQueryParams,
  };
};

/**
 * Filters out Taylor Swift from the provided list of music performers and returns up to 8 music performers.
 * @param unfilteredMusicPerformers Unfiltered list of music performers to filter.
 * @returns Up to 8 music performers with Taylor Swift filtered out.
 */
export const filterMusicPerformers = (unfilteredMusicPerformers: Performer[] | undefined): Performer[] => {
  return unfilteredMusicPerformers
    ? unfilteredMusicPerformers.filter(({ id, name }: Performer) => id !== TAYLOR_SWIFT_PERFORMER_ID && !matchesTaylorSwift(name)).slice(0, DEFAULT_PAGE_SIZE)
    : [];
};

/**
 * Gets array of event card props to render events in a carousel.
 * @returns {EventCardProps[]} Array of event card props.
 */
export const getEventCardProps = (params: {
  /** Events to get event card props for */
  events: Event[] | undefined;
  /** Event card variant (squareOnLight or squareOnDark) */
  eventCardVariant: 'squareOnLight' | 'squareOnDark';
  /** Property key of StaticCarouselSectionPropsByType constant that contains carousel title key and carousel index */
  carouselSectionPropsKey: keyof typeof StaticCarouselSectionPropsByType;
}): EventCardProps[] => {
  const { events = [], eventCardVariant, carouselSectionPropsKey } = params;

  return events.map((event: Event, eventCardIndex: number) => ({
    key: event.id,
    event,
    variant: eventCardVariant,
    pageName: TRACKING_PAGE_NAME.forEventCardClicks,
    sectionTitleKey: StaticCarouselSectionPropsByType[carouselSectionPropsKey].titleKey,
    sectionIndex: StaticCarouselSectionPropsByType[carouselSectionPropsKey].carouselIndex,
    eventCardIndex,
    className: styles.carouselItem,
  }));
};

/**
 * Gets array of performer card props to render performers in a carousel.
 * @returns {PerformerCardProps[]} Array of performer card props.
 */
export const getPerformerCardProps = (params: {
  /** Performers to get performer card props for */
  performers: Performer[] | undefined;
  /** Property key of StaticCarouselSectionPropsByType constant that contains carousel title key and carousel index */
  carouselSectionPropsKey: keyof typeof StaticCarouselSectionPropsByType;
}): PerformerCardProps[] => {
  const { performers = [], carouselSectionPropsKey } = params;

  return performers.map((performer: Performer, performerCardIndex: number) => ({
    key: performer.id,
    performer,
    pageName: TRACKING_PAGE_NAME.forPerformerCardClicks,
    sectionTitleKey: StaticCarouselSectionPropsByType[carouselSectionPropsKey].titleKey,
    sectionIndex: StaticCarouselSectionPropsByType[carouselSectionPropsKey].carouselIndex,
    performerCardIndex,
    className: cx(styles.carouselItem, styles.performersCarouselItem),
  }));
};

/**
 * Gets carousel section props for a specific type of carousel, e.g. cardholder exclusive events, upcoming events, etc.
 * @returns {CarouselSectionProps<TComponentProps>} Carousel section props.
 */
export const getCarouselSectionProps = <TComponentProps extends EventCardProps | PerformerCardProps>(params: {
  /** Optional View All button link. If not provided then View All button will not be rendered. */
  linkHref?: string;
  /** Optional click handler for View All button link */
  onLinkClick?: () => void;
  /** Indicates if items are loading */
  isLoading: boolean;
  /** Indicates if there is an error with loading items */
  isError: boolean;
  /** React component that is used to render each item in the carousel */
  component: FC<TComponentProps>;
  /** Array of all component props for rendering the list of components */
  componentProps: TComponentProps[];
  /** Property key of StaticCarouselSectionPropsByType constant that contains static carousel section props, e.g. carousel title key, carousel index, etc. */
  carouselSectionPropsKey: keyof typeof StaticCarouselSectionPropsByType;
}): CarouselSectionProps<TComponentProps> => {
  const { linkHref, onLinkClick, isLoading, isError, component, componentProps, carouselSectionPropsKey } = params;

  const { carouselIndex, ...staticCarouselSectionProps } = StaticCarouselSectionPropsByType[carouselSectionPropsKey];

  return {
    ...CommonCarouselSectionProps,
    ...staticCarouselSectionProps,
    linkHref,
    onLinkClick,
    isLoading,
    isError,
    component,
    componentProps,
  };
};
