import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { useModalState } from '../../../modules/modals';
import { getGoBackHistoryOffset } from '../../../modules/navigation/Navigation.utils';
import type { EventInfoHeaderPresenterProps, EventInfoHeaderProps } from './EventInfoHeader.types';
import { AuthContext } from '../../../modules/auth';
import { buildEventCardExclusiveBadgeProps } from '../../molecules/ExclusiveBadge/ExclusiveBadge.utils';
import { CardholderExclusiveBadgeThemesByTheme } from './EventInfoHeader.constants';

export const usePresenter = (props: EventInfoHeaderProps): EventInfoHeaderPresenterProps => {
  const navigate = useNavigate();
  const { eventMetaData, theme } = props;
  const { isExclusiveEvent, tags } = eventMetaData;

  const { trackEvent } = useAnalyticsManager();

  const { isSignedOut, accountCardDetails } = useContext(AuthContext);

  const { t } = useTranslation();

  const {
    isModalOpen: isInfoModalShown,
    openModal: onInfoButtonClick,
    closeModal: closeInfoModal,
  } = useModalState();

  const onBackButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('common.goBack'),
    );

    navigate(getGoBackHistoryOffset());
  }, [t, trackEvent, navigate]);

  const eventCardExclusiveBadgeProps = useMemo(() => isExclusiveEvent ? buildEventCardExclusiveBadgeProps({ isSignedOut, currentTags: tags, accountCardDetails, theme: CardholderExclusiveBadgeThemesByTheme[theme] }) : undefined, [accountCardDetails, isExclusiveEvent, isSignedOut, tags, theme]);

  return {
    ...props,
    isInfoModalShown,
    onInfoButtonClick,
    closeInfoModal,
    onBackButtonClick,
    eventCardExclusiveBadgeProps,
  };
};
