import { SUPPORTED_PROGRAM_TYPE_TAGS } from '../../../lib/constants';
import { formatStringToUpperSpace } from '../../../lib/util';
import type { AccountCardDetail } from '../../../modules/auth';
import i18n from '../../../modules/locale/i18n';
import { getAccountCardImageUrl } from '../AccountCard';
import { DefaultBadgeCardImageByTheme } from './ExclusiveBadge.constant';
import type { ExclusiveBadgeProps, ExclusiveBadgeTheme } from './ExclusiveBadge.types';

/** Filters account card details that match specified tags.*/
export const getAccountCardDetailsWithMatchingTags = (
  params: {
    /** Input array of account details */
    accountCardDetails: AccountCardDetail[];
    /** Tags to match against, default is an empty array */
    tagsToBeChecked: string[] | undefined;
  }): AccountCardDetail[] => {
  const { accountCardDetails, tagsToBeChecked = [] } = params;
  const tagsSet = new Set(tagsToBeChecked);

  // Filter account details based on matching tags
  const accountsWithMatchingTags = accountCardDetails.filter(accountDetail => {
    const { accountProgramTypeTag } = accountDetail;
    if (accountProgramTypeTag && tagsSet.has(accountProgramTypeTag)) {
      tagsSet.delete(accountProgramTypeTag); // Remove the tag from the set once matched
      return true; // Include this account detail in the filtered result
    }
    return false; // Exclude this account detail if no match
  });

  return accountsWithMatchingTags; // Return the filtered account details
};

// TODO: ND : ADD unit tests
/** Builds the properties for an exclusive badge based on user and account details */
export const buildEventCardExclusiveBadgeProps = (params: {
  /** Indicates whether the user is signed out */
  isSignedOut: boolean;
  /** The tags associated with the current event or listings */
  currentTags: string[] | undefined;
  /** The account card details available for the user */
  accountCardDetails: AccountCardDetail[];
  /** The theme of the exclusive badge */
  theme: ExclusiveBadgeTheme;
}): ExclusiveBadgeProps => {
  const { isSignedOut, currentTags = [], accountCardDetails, theme } = params;

  // Early return for signed-out users
  if (isSignedOut) {
    return {
      iconInfo: {
        iconAsset: 'Lock',
        iconStyle: 'CoreBlue50',
      },
      textValue: i18n.t('exclusiveBadge.exclusiveAccess'),
      theme,
    };
  }

  if (!currentTags?.length || !accountCardDetails.length || accountCardDetails.length <= 1) {
    return {
      iconInfo: {
        iconAsset: 'Sparkles',
        iconStyle: 'Yellow',
      },
      textValue: i18n.t('exclusiveBadge.cardholderExclusive'),
      theme,
    };
  }

  // Find account details matching the current tags
  const accountsWithMatchingTags = getAccountCardDetailsWithMatchingTags({
    accountCardDetails,
    tagsToBeChecked: currentTags,
  });

  if (accountsWithMatchingTags.length === 1) {
    const matchingAccount: AccountCardDetail = accountsWithMatchingTags[0];
    const programType: string = formatStringToUpperSpace(matchingAccount.accountProgramType);

    return {
      imageUrl: getAccountCardImageUrl({
        accountCardImageUrl: matchingAccount.accountCardImageUrl,
        theme: DefaultBadgeCardImageByTheme[theme],
      }),
      textValue: i18n.t('exclusiveBadge.programTypeExclusive', { programType }),
      theme,
    };
  }

  return {
    iconInfo: {
      iconAsset: 'Sparkles',
      iconStyle: 'Yellow',
    },
    textValue: i18n.t('exclusiveBadge.cardholderExclusive'),
    theme,
  };
};

// TODO: ND : ADD unit tests
export const buildListingCardExclusiveBadgeProps = (params: {
  /** Indicates whether the user is signed out */
  isSignedOut: boolean;
  /** The tags associated with the current event or listings */
  currentTags: string[] | undefined;
  /** The account card details available for the user */
  accountCardDetails: AccountCardDetail[];
  /** The theme of the exclusive badge */
  theme?: ExclusiveBadgeTheme;
}): ExclusiveBadgeProps | undefined => {
  const { isSignedOut, currentTags = [], accountCardDetails, theme = 'dark' } = params;

  if (isSignedOut || !currentTags?.length || !accountCardDetails.length || accountCardDetails.length <= 1) {
    return undefined;
  }

  // Get only program type tags from listing tags
  const listingProgramTypeTags: string[] = currentTags.filter((tag: string) => SUPPORTED_PROGRAM_TYPE_TAGS[tag]);
  if (!listingProgramTypeTags.length) {
    return undefined;
  }

  // Find account details matching the current tags
  const accountsWithMatchingTags = getAccountCardDetailsWithMatchingTags({
    accountCardDetails,
    tagsToBeChecked: listingProgramTypeTags,
  });

  if (accountsWithMatchingTags.length === 1) {
    const matchingAccount: AccountCardDetail = accountsWithMatchingTags[0];
    const programType: string = formatStringToUpperSpace(matchingAccount.accountProgramType);

    return {
      imageUrl: getAccountCardImageUrl({
        accountCardImageUrl: matchingAccount.accountCardImageUrl,
        theme: DefaultBadgeCardImageByTheme[theme],
      }),
      textValue: i18n.t('exclusiveBadge.programTypeExclusive', { programType }),
      theme,
    };
  }

  return undefined;
};
