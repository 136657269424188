import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT_ID_PARAM, LISTING_ID_PARAM, URLs } from '../../../lib/constants';
import { getEventUrl } from '../../../lib/eventUtils';
import { isNumber } from '../../../lib/util';
import { checkIfPathMatch } from '../../../modules/navigation/Navigation.utils';

/**
 * Hook that initialises browser history.
 *
 * If user initially goes to a non-home page then add home page to browser history.
 * This is required for navigating to home page when user clicks on back button in the header, e.g. on event pages.
 *
 * If user initially goes to checkout page then also add event page to browser history.
 * This is required for navigating to event page when user clicks on X button in the header.
 */
export const useInitialiseHistory = () => {
  const navigate = useNavigate();

  const isHistoryInitialisedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isHistoryInitialisedRef.current) {
      isHistoryInitialisedRef.current = true;

      const initialPathName: string = window.location.pathname;

      const isHomePage: boolean = checkIfPathMatch(initialPathName, URLs.HomePage, true);
      if (!isHomePage) {
        const initialQueryString = new URLSearchParams(window.location.search);
        const initialUrl: string = `${window.location.pathname}${window.location.search}${window.location.hash}`;

        // If user initially goes to a non-home page then add home page to browser history
        navigate(URLs.HomePage, { replace: true });

        const isEventPage: boolean = checkIfPathMatch(initialPathName, URLs.EventPage, true);
        if (isEventPage && initialQueryString.get(LISTING_ID_PARAM)) {
          // If user initially goes to pre-checkout page then also add event page to browser history
          // This is required for navigating to event page when user clicks on 'Back to all tickets' button
          navigate(initialPathName);
        }

        const isCheckoutPage: boolean = checkIfPathMatch(initialPathName, URLs.CheckoutPage, true);
        if (isCheckoutPage) {
          const eventId: number = Number(initialQueryString.get(EVENT_ID_PARAM));
          if (isNumber(eventId) && eventId > 0) {
            // If user initially goes to checkout page then also add event page to browser history
            // This is required for navigating to event page when user clicks on X button in the header
            navigate(getEventUrl(eventId));
          }
        }

        navigate(initialUrl);
      }
    }
  }, [navigate]);
};
