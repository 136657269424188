import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AuthContext } from '../../../modules/auth/AuthContext';
import { getEvent, getFilesByName, getMyOrders } from '../../../modules/partnership/api';

const useInteractor = () => {
  const { isAccountLoading, account } = useContext(AuthContext);
  const { mutateAsync: getEventById, isLoading: eventLoading } = useMutation(getEvent);
  const { error, data, isLoading: ordersLoading } = useQuery(
    ['getMyOrders'],
    () => getMyOrders(),
  );
  const { mutateAsync: getFile } = useMutation(getFilesByName);

  return {
    pageLoading: isAccountLoading || ordersLoading,
    eventLoading,
    error: error as Error,
    data,
    account,
    getEvent: getEventById,
    downloadTickets: getFile,
  };
};

export default useInteractor;
