import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import type { ButtonCombinedProps } from '../../atoms/Button';
import { useTermsOfUseModal } from '../TermsOfUseModal';
import type { FooterPresenterProps, FooterProps, FooterTheme } from './Footer.types';
import { getFooterButtons, getFooterTheme } from './Footer.utils';

export const usePresenter = (props: FooterProps): FooterPresenterProps => {
  const { shouldExcludeFooterButtons = false, theme: initialTheme } = props;

  const { pathname } = useLocation();

  const theme: FooterTheme = useMemo(() => initialTheme || getFooterTheme({ pathname }), [initialTheme, pathname]);

  const { openTermsOfUseModal } = useTermsOfUseModal();

  const footerButtons: ButtonCombinedProps[] = useMemo(
    () => getFooterButtons({ shouldExcludeFooterButtons, theme, openTermsOfUseModal }),
    [shouldExcludeFooterButtons, theme, openTermsOfUseModal],
  );

  return {
    ...props,
    theme,
    footerButtons,
  };
};
