import { RewardUnit } from '../../../lib/util';
import { Account } from '../../../modules/auth';
import type { PresetFilterOption } from '../../organisms/PresetFilter';
import { REWARD_TAGS_BY_PRIORITY, REWARD_TAGS_ID_MAP, REWARD_TAGS_MAP } from './RewardsSwitcher.constants';
import type { RewardTag } from './RewardsSwitcher.types';

/**
 * Gets loyalty unit tags for an account's loyalty program, e.g., C1_MILES, C1_CASH_REWARDS, C1_POINTS
 *
 * This function takes a list of reward tags and filters them by a predefined order of priority
 * from `REWARD_TAGS_BY_PRIORITY`. It ensures that only the valid tags, in the order of importance,
 * are returned based on the available tags in the input.
 *
 * @param {string[] | undefined} tags - A list of reward tags from the account's loyalty program.
 * @returns {RewardTag[] | undefined} - An ordered array of reward tags based on priority, or undefined if no valid tags are found.
 */
export const getRewardTags = (tags: string[] | undefined): RewardTag[] | undefined => {
  // Return undefined if no tags are provided or if the tags array is empty
  if (!tags?.length) {
    return undefined;
  }

  // Filter the available tags using the REWARD_TAGS_BY_PRIORITY array,
  // ensuring only valid reward tags are returned in the defined order of priority
  return REWARD_TAGS_BY_PRIORITY.filter((tag: RewardTag) => tags.includes(tag));
};

/**
 * Constructs the category filter options for the given active tab key.
 *
 * This function converts the reward tags from the event into a list of filter options that can be used
 * in the UI. Each filter option will have a unique id and a name. The id is sourced from `REWARD_TAGS_ID_MAP`
 * (which maps reward tags to their ids), and the name is sourced from `REWARD_TAGS_MAP` (which maps reward tags
 * to their display names). If no id is found for a reward tag, the index is used as the fallback id.
 *
 * @returns {PresetFilterOption[]} - An array of filter options for the UI, each with an id and display name.
 */
export const getRewardTagsFilterOptions = (params: {
  /** RewardTags list from event */
  rewardTags: RewardTag[] | undefined;
}): PresetFilterOption[] => {
  const { rewardTags } = params;

  // Return an empty array if no reward tags are available
  if (!rewardTags?.length) {
    return [];
  }

  // Map each reward tag to a filter option object with id and name
  // If the reward tag is not mapped in REWARD_TAGS_ID_MAP, use the array index as a fallback id
  return rewardTags.map((tag, index) => ({
    id: REWARD_TAGS_ID_MAP[tag] || index,  // Use the mapped id or index as a fallback
    name: REWARD_TAGS_MAP[tag] || '',      // Use the mapped name or an empty string as fallback
  }));
};

/**
 * Filters and prioritizes reward tags based on an account's loyalty program.
 *
 * This function takes an account and a list of reward tags and returns an array of reward tags 
 * that are valid and match the loyalty program(s) of the account. The resulting tags are ordered 
 * by a predefined priority specified in `REWARD_TAGS_BY_PRIORITY`.
 *
 * @param {Account | undefined} account - The account object containing loyalty program details.
 * @param {string[] | undefined} tags - A list of reward tags to be filtered and matched.
 * @returns {RewardTag[]} - An array of reward tags that match the account's loyalty program(s), 
 *                          ordered by priority. Returns an empty array if no matches are found.
 */
export const getMatchingRewardsFromAccount = (params: {
  account: Account | undefined;
  tags: string[] | undefined;
}): RewardTag[] => {
  const { account, tags } = params;

  // Return an empty array if no account or reward tags are available
  if (!account || !tags?.length) {
    return [];
  }

  // Define a mapping from RewardTag to RewardUnit
  const rewardTagToUnitMap: Record<RewardTag, RewardUnit> = {
    C1_MILES: RewardUnit.MILES,
    C1_CASH_REWARDS: RewardUnit.CASH_REWARDS,
    C1_POINTS: RewardUnit.POINTS,
  };

  // Create a set of reward tags for comparison
  const rewardTagSet = new Set<RewardTag>(
    tags.filter((tag): tag is RewardTag => tag in rewardTagToUnitMap),
  );

  // Extract loyalty unit names from the account and its associated accounts
  const associatedAccountsRewards = account.associatedAccounts
    ?.map((associatedAccount) => associatedAccount.loyalty_program.loyalty_unit_name)
    .filter((unitName): unitName is RewardUnit => !!unitName); // Narrow down to valid RewardUnit

  const accountRewardUnits = new Set<RewardUnit>([
    account.loyalty_program.loyalty_unit_name,
    ...(associatedAccountsRewards ?? []),
  ]);

  // Map reward tags to their corresponding RewardUnits and check for matches
  const matchingRewards = Array.from(rewardTagSet).filter((tag) =>
    accountRewardUnits.has(rewardTagToUnitMap[tag]),
  );

  return REWARD_TAGS_BY_PRIORITY.filter((tag: RewardTag) => matchingRewards.includes(tag));
};
