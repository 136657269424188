import { useEffect } from 'react';
import { trackPageViewEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { OnBoardingModalPresenterProps, OnBoardingModalProps } from './OnBoardingModal.types';

export const usePresenter = (props: OnBoardingModalProps): OnBoardingModalPresenterProps => {
  const { isOnBoardingModalOpen } = props;

  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    if (isOnBoardingModalOpen) {
      trackPageViewEvent(
        trackEvent,
        'Onboarding Screen',
      );
    }
  }, [isOnBoardingModalOpen, trackEvent]);

  return props;
};
