import type { AriaRole, KeyboardEventHandler, RefObject, SyntheticEvent } from 'react';
import type { Account } from '../../../modules/auth/types';
import type { AccountMenuValueProps } from '../../molecules/AccountMenu';

export type TopNavTypeEnum = 'Mobile' | 'Mobile2Rows' | 'MobileWithSearchAndBack' | 'Desktop' | 'LogoWithClose' | 'LogoOnly';

export type TopNavProps = {
  topNavType?: TopNavTypeEnum;
  isLogoClickable?: boolean;
  className?: string;
};

export type TopNavPresenterProps = TopNavProps & {
  account?: Account;
  isSignedOut?: boolean;
  topNavType: TopNavTypeEnum;
  topNavRef: RefObject<HTMLDivElement>;
  onLogoClick: () => void;
  onSignInButtonClick: () => void;
  onMenuButtonClick: () => void;
  onCloseButtonClick: () => void;
  accountMenu: AccountMenuValueProps;
};

export type AccessibilityActions = {
  onClick?: (event: SyntheticEvent) => void;
  onTab?: (forward: boolean) => void;
  onEsc?: () => void;
};

export type AccessibilityProps = {
  tabIndex?: number;
  role?: AriaRole;
  onKeyDown?: KeyboardEventHandler<unknown>;
};

/**
 * Positions define tab action, if an item is first, then
 * shift+tab should have a special action, if it's the last,
 * then tab should have a special action
 */
export enum MenuItemPositions {
  FIRST,
  LAST,
}
