import React, { type ReactNode } from 'react';
import { Trans } from 'react-i18next';
import styles from './OnBoardingModalContent.module.scss';
import type { StepDescriptions } from './OnBoardingModalContent.types';

export const CAPITAL_ONE_LINK = 'https://capitalone.com';

export const ONBOARDING_STEPS = {
  StepOne: 1,
  StepTwo: 2,
  StepThree: 3,
} as const;

/** The last step number in the onboarding process (used to identify when the user is on the final step) */
export const LAST_STEP = ONBOARDING_STEPS.StepThree;

export const MAX_NUMBER_OF_CARDS_TO_DISPLAY_BILLING_ADDRESS = 1;

/** Background orientation for the mobile breakpoint */
export const MOBILE_LINEAR_BACKGROUND_ORIENTATION = '180deg';

/** Background orientation for the desktop breakpoint */
export const DESKTOP_LINEAR_BACKGROUND_ORIENTATION = '-90deg';

/** Titles for each step in the onboarding process, keyed by step number */
export const TITLES_BY_STEP: Record<number, string> = {
  [ONBOARDING_STEPS.StepOne]: 'onBoardingModal.step1.title',
  [ONBOARDING_STEPS.StepTwo]: 'onBoardingModal.step2.title',
  [ONBOARDING_STEPS.StepThree]: 'onBoardingModal.step3.title',
} as const;

/** Subtitles for each step in the onboarding process, keyed by step number */
export const SUB_TITLES_BY_STEP: Record<number, ReactNode> = {
  [ONBOARDING_STEPS.StepOne]: <Trans i18nKey='onBoardingModal.step1.subTitle' />,
  [ONBOARDING_STEPS.StepTwo]: <Trans i18nKey='onBoardingModal.step2.subTitle' />,
  [ONBOARDING_STEPS.StepThree]: <Trans
    i18nKey='onBoardingModal.step3.subTitle'
    components={{
      capOneLink: <a href={CAPITAL_ONE_LINK} target='_blank' rel='noreferrer' className={styles.underlineText} />,
    }} />,
} as const;

/** Descriptions for each step, keyed by step number */
export const DESCRIPTIONS_BY_STEP: StepDescriptions = {
  [ONBOARDING_STEPS.StepOne]: [
    {
      descriptionKey: 'onBoardingScreen.step1.description1',
      asset: 'EntertainmentTickets',
    },
    {
      descriptionKey: 'onBoardingScreen.step1.description2',
      asset: 'Vector',
    },
    {
      descriptionKey: 'onBoardingScreen.step1.description3',
      asset: 'RedeemRewards',
    },
  ],
  [ONBOARDING_STEPS.StepTwo]: [
    {
      descriptionKey: 'onBoardingScreen.step2.description1',
      asset: 'Step1',
    },
    {
      descriptionKey: 'onBoardingScreen.step2.description2',
      asset: 'Step2',
    },
    {
      descriptionKey: 'onBoardingScreen.step2.description3',
      asset: 'Step3',
    },
  ],
};
