import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getAccountCardDetails, getAccountTags } from '../../lib/accountUtils';
import { EMPTY_ACCOUNT_TAGS, URLs } from '../../lib/constants';
import { areObjectsEqual } from '../../lib/objectUtils';
import type { Props } from '../../lib/types';
import { getSessionCookie, removeSessionCookie } from '../../lib/util';
import { LoyaltyCurrenciesContext } from '../loyaltyCurrencies';
import { UserIdleContext } from '../userIdle';
import { getAuthData, logoutUser, verifyToken } from './AuthApi';
import type { Account, AccountCardDetail, AccountData, AccountTags, AuthContextValue, SignedInAccountData, VerifyTokenPayload } from './types';

export const Logout: React.FC<{}> = () => {
  async function logout() {
    await logoutUser();

    removeSessionCookie();

    window.location.assign(URLs.HomePage);
  }

  void logout();

  return null;
};

const initialAuthContext: AuthContextValue = {
  isAccountLoading: false,
  isSignedIn: false,
  account: undefined,
  isSignedOut: true,
  error: undefined,
  refetchAccount: () => { },
  setAccountExternally: () => { },
  verifyAccessToken: () => { },
  accountCardDetails: [],
  selectedAccountCardIndex: undefined,
  selectAccountCardByIndex: () => { },
  selectedAccountCardDetail: undefined,
  accountTags: EMPTY_ACCOUNT_TAGS,
  selectedAccountCardTags: undefined,
};

export const AuthContext = createContext<AuthContextValue>(initialAuthContext);

const getSha1Hash = async (email: string): Promise<string> => {
  // Convert email to lowercase and remove spaces
  const formattedEmail = email.toLowerCase().replace(/\s/g, '');

  // In non-https contexts (e.g. local development) crypto.subtle is unavailable.
  // In these cases return formatted email.
  if (location.protocol === 'http:' && !crypto.subtle) {
    return formattedEmail;
  }

  // Generate SHA-1 hash
  const encoder = new TextEncoder();
  const data = encoder.encode(formattedEmail);
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const sessionCookie: string | undefined = getSessionCookie();

  const { stopSession, setIsAuthenticated } = useContext(UserIdleContext);

  const { loyaltyCurrencies } = useContext(LoyaltyCurrenciesContext);

  // If session cookie is present, default to loading before starting to fetch for account data, else set account state to 'signed-out'
  const [accountData, setAccountData] = useState<AccountData>({
    state: !!sessionCookie ? 'signed-in-loading' : 'signed-out',
  });

  const isAccountLoading: boolean = useMemo(() => accountData.state === 'signed-in-loading', [accountData.state]);
  const isSignedIn: boolean = useMemo(() => accountData.state === 'signed-in', [accountData.state]);
  const isSignedOut: boolean = useMemo(() => accountData.state === 'signed-out', [accountData.state]);

  // Hash of the account email
  const [hashedEmail, setHashedEmail] = useState<string>('');

  useEffect(() => {
    const getAndSetHashedEmail = async () => {
      if (accountData.state === 'signed-in' && accountData.account?.email) {
        const newHashedEmail: string = await getSha1Hash(accountData.account.email);
        setHashedEmail(newHashedEmail);
      }
    };

    void getAndSetHashedEmail();
  }, [accountData]);

  const fetchAccount = useCallback(async () => {
    setAccountData((prevState: AccountData) => {
      return prevState.state !== 'signed-in-loading'
        ? { state: 'signed-in-loading' }
        : prevState;
    });

    try {
      const account: Account = await getAuthData();

      setAccountData((prevState: AccountData) => {
        const newAccountState: SignedInAccountData = { state: 'signed-in', account };
        return areObjectsEqual(prevState, newAccountState)
          ? prevState
          : newAccountState;
      });

      setIsAuthenticated(true);
    } catch (e) {
      setAccountData({ state: 'error', error: e as Error });
      setIsAuthenticated(false);
      stopSession();
    }
  }, [setIsAuthenticated, stopSession]);

  const verifyAccessToken = useCallback(async (payload: VerifyTokenPayload) => {
    try {
      setAccountData({ state: 'signed-in-loading' });
      await verifyToken(payload);
      await fetchAccount();
    } catch (e) {
      setAccountData({ state: 'error', error: e as Error });
      setIsAuthenticated(false);
      stopSession();
    }
  }, [fetchAccount, setIsAuthenticated, stopSession]);

  useEffect(() => {
    const shouldFetch =
      accountData.state === 'signed-in-loading' ||
      (accountData.state !== 'signed-in' && accountData.state !== 'error');

    if (sessionCookie && shouldFetch) {
      void fetchAccount();
    }
  }, [sessionCookie, accountData, fetchAccount]);

  const refetchAccount = useCallback(() => {
    void fetchAccount();
  }, [fetchAccount]);

  /** Function to set account manually. Called during checkout eligibility checks. */
  const setAccountExternally = useCallback((account: Account) => {
    setAccountData({ state: 'signed-in', account });
  }, []);

  // TODO: Uncomment this useEffect when using Optimizely experiment
  // // Save user Id in local storage which is required for Optimizely initialisation
  // useEffect(() => {
  //   if (accountData.state === 'signed-in' && accountData.account.id) {
  //     const previousUserId: string | null = localStorage.getItem(USER_ID);
  //     const currentUserId: string = accountData.account.id.toString();

  //     // To ensure user sees Optimizely variation consistently we need to reload the site if user Id changes
  //     if (previousUserId !== currentUserId) {
  //       localStorage.setItem(USER_ID, currentUserId);
  //       window.location.reload();
  //     }
  //   }
  // }, [accountData]);

  const accountCardDetails: AccountCardDetail[] = useMemo(() => {
    if (accountData.state === 'signed-in') {
      return getAccountCardDetails({ account: accountData.account, loyaltyCurrencies });
    }
    return [];
  }, [accountData, loyaltyCurrencies]);

  /** Object that contains loyalty, program, and processing network tags for the account */
  const accountTags: AccountTags = useMemo(() => getAccountTags({ accountCardDetails }), [accountCardDetails]);

  // TODO: AK: Use selectedAccountCardIndex for multicard experience in the future
  // 0-based index of the selected account card
  const [selectedAccountCardIndex, setSelectedAccountCardIndex] = useState<number | undefined>(undefined);

  /** Function to select account card by 0-based index */
  const selectAccountCardByIndex = useCallback((newSelectedAccountCardIndex: number | undefined) => {
    setSelectedAccountCardIndex(newSelectedAccountCardIndex);
  }, []);

  /** Selected account card detail */
  const selectedAccountCardDetail: AccountCardDetail | undefined = useMemo(
    () => typeof selectedAccountCardIndex === 'number' ? accountCardDetails[selectedAccountCardIndex] : undefined,
    [accountCardDetails, selectedAccountCardIndex],
  );
  /**Selected account Tags*/
  const selectedAccountCardTags: AccountTags | undefined = useMemo(() => selectedAccountCardDetail ? getAccountTags({ accountCardDetails: [selectedAccountCardDetail] }) : undefined, [selectedAccountCardDetail]);

  return (
    <AuthContext.Provider value={{
      ...accountData,
      isAccountLoading,
      isSignedIn,
      isSignedOut,
      refetchAccount,
      hashedEmail,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      verifyAccessToken,
      setAccountExternally,
      accountCardDetails,
      selectedAccountCardIndex,
      selectAccountCardByIndex,
      selectedAccountCardDetail,
      accountTags,
      selectedAccountCardTags,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
