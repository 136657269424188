import { LoyaltyCurrencies, LoyaltyCurrency } from '../../../modules/partnership';
import { REWARD_TAGS_ID_MAP } from '../RewardsSwitcher';

// Utility function to map a RewardTag to a LoyaltyCurrency
export const mapTagToLoyaltyCurrency = (
  tag: string,
  loyaltyCurrencies: LoyaltyCurrencies | undefined,
): LoyaltyCurrency | undefined => {
  if (!loyaltyCurrencies) return undefined;

  const currencyId = REWARD_TAGS_ID_MAP[tag];
  return currencyId ? loyaltyCurrencies[currencyId] : undefined;
};

// Utility function to find the first matching LoyaltyCurrency from a list of tags
export const findFirstMatchingCurrency = (
  tags: string[],
  loyaltyCurrencies: LoyaltyCurrencies | undefined,
): LoyaltyCurrency | undefined => {
  return tags
    .map((tag) => mapTagToLoyaltyCurrency(tag, loyaltyCurrencies))
    .find((currency) => currency !== undefined);
};

export const getDisplayLoyaltyCurrency = (params: {
  selectedLoyaltyCurrency: LoyaltyCurrency,
  tags: string[] | undefined,
}): LoyaltyCurrency | undefined => {
  const { selectedLoyaltyCurrency, tags } = params;

  // Step 1: Find the RewardTag that corresponds to selectedCurrency.id
  const matchingRewardTag = Object.keys(REWARD_TAGS_ID_MAP).find(
    (rewardTag) => REWARD_TAGS_ID_MAP[rewardTag] === selectedLoyaltyCurrency.id,
  );

  if (!matchingRewardTag) {
    // If no RewardTag matches the selectedCurrency.id, return undefined
    return undefined;
  }

  // Step 2: Check if the matchingRewardTag exists in the tags array
  const hasMatchingTag = tags?.includes(matchingRewardTag);

  // Step 3: Return the selectedCurrency if a match is found; otherwise, undefined
  return hasMatchingTag ? selectedLoyaltyCurrency : undefined;
};
