import CreditCard from '../../../resources/icons/CreditCard.svg';
import CreditCardBlack from '../../../resources/icons/CreditCardBlack.svg';
import type { TextColourEnum } from '../../atoms/Text';
import type { AccountCardTheme } from './AccountCard.types';

export const AccountCardThemes = ['light', 'subduedLight', 'subduedDark'] as const;

export const AccountCardFallbackImageUrlsByTheme = {
  light: CreditCard,
  subduedLight: CreditCardBlack,
  subduedDark: CreditCardBlack,
} as const satisfies Record<AccountCardTheme, string>;

export const AccountCardInfoColoursByTheme = {
  light: 'BaseLight',
  subduedLight: 'SubduedLight',
  subduedDark: 'SubduedDark',
} as const satisfies Record<AccountCardTheme, TextColourEnum>;
