import cx from 'classnames';
import { EXCLUSIVE_EVENTS_PAGE_ANCHORS } from '../../../lib/constants';
import type { BaseComponentProps } from '../../../lib/types';
import type { CarouselSectionProps } from '../../organisms/CarouselSection';
import styles from './ExclusiveEventsPage.module.scss';

export const TRACKING_PAGE_NAME = {
  forPageVisits: 'Exclusive Event Landing Page',
  forCarouselLinkClicks: 'Exclusive Event Landing Page',
  forEventCardClicks: 'Exclusive Event Landing Page',
  forMlbCardClicks: 'Exclusive Events Landing Page',
  /** Used for clicks on Dining banner */
  forBannerClicks: 'Exclusive Events Landing Page',
  /** Used for view_item_list tracking */
  forViewItemList: 'exclusive_landing_page_view',
} as const;

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 8;

export const SPOTLIGHT_EVENTS_PAGE_SIZE = 4;
export const MIN_SPOTLIGHT_EVENTS_TO_SHOW = 2;

export const CommonCarouselSectionProps = {
  theme: 'dark',
  linkLabelKey: 'exclusiveEventsPage.viewAll',
  emptyStateTitleKey: 'exclusiveEventsPage.emptyStateTitle',
  emptyStateDescriptionKey: 'exclusiveEventsPage.emptyStateDescription',
  areBoxShadowsEnabled: true,
  pageName: TRACKING_PAGE_NAME.forCarouselLinkClicks,
} as const satisfies Partial<CarouselSectionProps<BaseComponentProps>>;

export const StaticCarouselSectionPropsByType = {
  spotlight: {
    carouselIndex: 1,
    titleKey: 'exclusiveEventsPage.carouselSections.spotlight',
    isEmptyStateSupported: false,
    classes: {
      carouselHeader: cx(styles.mobileCarouselHeader, styles.spotlightEventsCarouselHeader),
      carouselTitle: styles.spotlightEventsCarouselTitle,
      carouselLoadingOrEmptyStateContainer: cx(styles.carouselLoadingOrEmptyStateContainer, styles.spotlightEventsCarouselLoadingOrEmptyStateContainer),
      carouselItemsContainer: cx(styles.carouselItemsContainer, styles.spotlightEventsCarouselItemsContainer),
    },
  },
  sports: {
    carouselIndex: 2,
    titleKey: 'exclusiveEventsPage.carouselSections.sports',
    isEmptyStateSupported: true,
    htmlId: EXCLUSIVE_EVENTS_PAGE_ANCHORS.sports,
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.carouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: styles.carouselItemsContainer,
    },
  },
  music: {
    carouselIndex: 3,
    titleKey: 'exclusiveEventsPage.carouselSections.music',
    isEmptyStateSupported: true,
    htmlId: EXCLUSIVE_EVENTS_PAGE_ANCHORS.music,
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.carouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: styles.carouselItemsContainer,
    },
  },
  dining: {
    carouselIndex: 4,
    titleKey: 'exclusiveEventsPage.carouselSections.dining',
    isEmptyStateSupported: true,
    htmlId: EXCLUSIVE_EVENTS_PAGE_ANCHORS.dining,
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.carouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: styles.carouselItemsContainer,
    },
  },
  getaways: {
    carouselIndex: 5,
    titleKey: 'exclusiveEventsPage.carouselSections.getaways',
    isEmptyStateSupported: true,
    htmlId: EXCLUSIVE_EVENTS_PAGE_ANCHORS.getaways,
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.carouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: styles.carouselItemsContainer,
    },
  },
  artTheater: {
    carouselIndex: 6,
    titleKey: 'exclusiveEventsPage.carouselSections.artTheater',
    isEmptyStateSupported: true,
    htmlId: EXCLUSIVE_EVENTS_PAGE_ANCHORS.artTheater,
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.carouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: styles.carouselItemsContainer,
    },
  },
} as const satisfies Record<string, Partial<CarouselSectionProps<BaseComponentProps>> & { carouselIndex: number; }>;

/** Query parameter key for the selected card index */
export const SELECTED_CARD_INDEX_PARAM: string = 'selectedCardIndex';