import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { URLs } from '../../../lib/constants';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import styles from './NoListings.module.scss';
import { usePresenter } from './NoListings.presenter';
import type { NoListingsProps } from './NoListings.types';

export const NoListings: React.FC<NoListingsProps> = (props) => {
  const {
    onGoToHomeButtonClick,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={cx(styles.noListings, className)}>
      <Text
        type='Subheading'
        size='Small'
        style='Regular'
        colour='SubduedDark'
        align='Center'
        value={t('noListings.title')}
      />
      <Text
        type='Body'
        size='Large'
        style='Regular'
        colour='SubduedDark'
        align='Center'
        value={t('noListings.message')}
        className={styles.message}
      />
      <Button
        type='Text'
        style='Outline'
        size='Medium'
        text={{
          value: t('noListings.buttonText'),
          classes: { value: styles.goToHomeButtonText },
        }}
        href={URLs.HomePage}
        onClick={onGoToHomeButtonClick}
        className={styles.goToHomeButton}
      />
    </div>
  );
};
