import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../atoms/Text';
import { AccountCard, type AccountCardProps } from '../AccountCard';
import { RadioButton } from '../RadioButton';
import { AccountCardRadioButtonThemesByRewardsBalanceTheme, TitleColoursByRewardsBalanceTheme } from './RewardsBalance.constants';
import styles from './RewardsBalance.module.scss';
import { usePresenter } from './RewardsBalance.presenter';
import type { RewardsBalanceProps } from './RewardsBalance.types';

export const RewardsBalance: React.FC<RewardsBalanceProps> = (props) => {
  const {
    selectedAccountCardIndex,
    selectAccountCardByIndex,
    accountCardProps,
    theme,
  } = usePresenter(props);

  const { t } = useTranslation();

  if (!accountCardProps.length) {
    return null;
  }

  const accountCardRadioButtons: ReactNode = accountCardProps.map((currentAccountCardProps: AccountCardProps, accountCardIndex: number) => (
    <RadioButton
      key={accountCardIndex}
      value={accountCardIndex}
      isSelected={accountCardIndex === selectedAccountCardIndex}
      onClick={selectAccountCardByIndex}
      theme={AccountCardRadioButtonThemesByRewardsBalanceTheme[theme]}
      className={styles.accountCardRadioButton}
      isDisabled={currentAccountCardProps?.isDisabled}
    >
      <AccountCard
        {...currentAccountCardProps}
        key={currentAccountCardProps.key}
      />
    </RadioButton>
  ));

  return (
    <div className={cx(styles.rewardsBalance, styles[`${theme}RewardsBalance`])}>
      <Text
        size='Medium'
        style='Regular'
        colour={TitleColoursByRewardsBalanceTheme[theme]}
        value={t('rewardsBalance.yourRewardsBalance')}
        className={styles.title}
      />
      <div className={styles.accountCardRadioButtons}>
        {accountCardRadioButtons}
      </div>
    </div>
  );
};
