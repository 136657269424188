import cx from 'classnames';
import { useMemo } from 'react';
import styles from './HighlightedMessage.module.scss';
import type { HighlightedMessagePresenterProps, HighlightedMessageProps } from './HighlightedMessage.types';

export const usePresenter = (props: HighlightedMessagePresenterProps): HighlightedMessageProps => {
  const { background, className: initialClassName } = props;

  const className: string = useMemo(
    () => cx(styles.highlightedMessage, styles[`${background}HighlightedMessage`], initialClassName),
    [background, initialClassName],
  );

  return {
    ...props,
    className,
  };
};
