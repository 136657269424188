import { useCallback, useEffect, useMemo, useState, type SyntheticEvent } from 'react';
import type { Dropdown } from 'react-bootstrap';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import type { IconStyleEnum } from '../Icon';
import type { TextColourEnum } from '../Text';
import { NON_TOGGLABLE } from './BaseFilter.constants';
import type { BaseFilterPresenterProps, BaseFilterProps } from './BaseFilter.types';
import { calculateDisplayValue, calculateDisplayValueColour, calculateFilterIconStyle } from './BaseFilter.utils';

export const usePresenter = <TFilterState, TFilterOptionValue>(props: BaseFilterProps<TFilterState, TFilterOptionValue>): BaseFilterPresenterProps<TFilterState, TFilterOptionValue> => {
  const {
    filterState,
    getDisplayValue,
    placeholder,
    onFilterOptionSelected: initialOnFilterOptionSelected,
    onFilterDropdownToggled,
    isClearable,
    isDisabled,
    isHighlightOnExpandOnly = false,
    theme,
  } = props;

  const { trackEvent } = useAnalyticsManager();

  // Indicates whether the filter dropdown is expanded
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  /** Display value of the filter state */
  const displayValue: string = useMemo(() => {
    return calculateDisplayValue({ filterState, placeholder, getDisplayValue });
  }, [filterState, placeholder, getDisplayValue]);

  // Close filter dropdown whenever filter state changes
  useEffect(() => {
    setIsExpanded(false);
  }, [filterState]);

  /** Function to toggle filter dropdown */
  const toggleFilterDropdown = useCallback((isOpen: boolean, event: SyntheticEvent<Dropdown>) => {
    if (event.target instanceof Element) {
      const isNonTogglable: boolean = !!event.target.closest(`.${NON_TOGGLABLE}`);
      if (isNonTogglable) {
        return;
      }
    }

    setIsExpanded(isOpen);

    onFilterDropdownToggled?.(isOpen);

    if (isOpen) {
      trackSelectContentEvent(
        trackEvent,
        'Filter',
        'Button',
        displayValue,
      );
    }
  }, [displayValue, onFilterDropdownToggled, trackEvent]);

  /** Callback when a filter option is selected */
  const onFilterOptionSelected = useCallback((filterOptionValue: TFilterOptionValue | undefined, filterOptionText: string) => {
    initialOnFilterOptionSelected(filterOptionValue, filterOptionText);

    trackSelectContentEvent(
      trackEvent,
      'Filter',
      'FilterOption',
      filterOptionText,
    );
  }, [initialOnFilterOptionSelected, trackEvent]);

  /** Indicates whether clear filter button should be shown */
  const isClearFilterButtonShown: boolean = useMemo(() => isClearable && !!filterState, [isClearable, filterState]);

  /** Function to clear filter state */
  const clearFilter = useCallback(() => {
    setIsExpanded(false);
    onFilterOptionSelected(undefined, placeholder);
  }, [onFilterOptionSelected, placeholder]);

  /** Colour for the display value of the filter state */
  const displayValueColour: TextColourEnum = useMemo(() => {
    return calculateDisplayValueColour({ theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly });
  }, [theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly]);

  /** Icon style for the filter */
  const filterIconStyle: IconStyleEnum = useMemo(() => {
    return calculateFilterIconStyle({ theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly });
  }, [theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly]);

  return {
    ...props,
    displayValue,
    displayValueColour,
    isExpanded,
    toggleFilterDropdown,
    onFilterOptionSelected,
    isClearFilterButtonShown,
    clearFilter,
    filterIconStyle,
  };
};
