import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../lib/util';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { LimitedAccessModalPropsByTheme } from './LimitedAccessModal.constants';
import styles from './LimitedAccessModal.module.scss';
import { usePresenter } from './LimitedAccessModal.presenter';
import type { LimitedAccessModalProps } from './LimitedAccessModal.types';

export const LimitedAccessModal: React.FC<LimitedAccessModalProps> = (props) => {
  const {
    titleTextKey = 'limitedAccessModal.title',
    contentTextKey = 'limitedAccessModal.description',
    actionButtonTextKey = 'limitedAccessModal.returnToTickets',
    closeModal,
    theme,
    className,
  } = usePresenter(props);

  const { isMobile } = useWindowSize();
  const breakpointKey: 'mobile' | 'desktop' = isMobile ? 'mobile' : 'desktop';

  const { t } = useTranslation();

  const title: ReactNode = (
    <Text
      type={LimitedAccessModalPropsByTheme[theme].titleTextType[breakpointKey]}
      size={LimitedAccessModalPropsByTheme[theme].titleTextSize[breakpointKey]}
      style='SemiBold'
      colour={LimitedAccessModalPropsByTheme[theme].titleTextColor}
      value={t(titleTextKey)}
    />
  );

  const closeButton: ReactNode = !isMobile && (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      className={styles.closeButton}
      focusable={true}
      icon={{
        asset: 'Close',
        style: LimitedAccessModalPropsByTheme[theme].closeIconColor,
        className: styles.closeButtonIcon,
      }}
      onClick={closeModal}
    />
  );

  const content: ReactNode = (
    <Text
      size={LimitedAccessModalPropsByTheme[theme].contentTextSize[breakpointKey]}
      style='Regular'
      colour={LimitedAccessModalPropsByTheme[theme].contentTextColor}
      align={LimitedAccessModalPropsByTheme[theme].contentTextAlign[breakpointKey]}
      value={t(contentTextKey)}
    />
  );

  const actionButton: ReactNode = (
    <Button
      type='Text'
      style={LimitedAccessModalPropsByTheme[theme].actionButtonStyle}
      size='Large'
      focusable={true}
      text={{
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        align: 'Center',
        value: t(actionButtonTextKey),
      }}
      onClick={closeModal}
      className={styles.actionButton}
    />
  );

  return (
    <Modal
      show={true}
      className={cx(styles.modal, styles[theme], className)}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      onHide={closeModal}
    >
      <div className={styles.limitedAccessModalContent}>
        <div className={styles.limitedAccessModalHeader}>
          {title}
          {closeButton}
        </div>
        {content}
        {actionButton}
      </div>
    </Modal>
  );
};
