import { useMemo } from 'react';
import type { AccountCardPresenterProps, AccountCardProps } from './AccountCard.types';
import { getAccountCardImageUrl } from './AccountCard.utils';

export const usePresenter = (props: AccountCardProps): AccountCardPresenterProps => {
  const { accountCardImageUrl: initialAccountCardImageUrl, theme } = props;

  /** Image URL of the account card */
  const accountCardImageUrl: string = useMemo(
    () => getAccountCardImageUrl({ accountCardImageUrl: initialAccountCardImageUrl, theme }),
    [initialAccountCardImageUrl, theme],
  );

  return {
    ...props,
    accountCardImageUrl,
  };
};
