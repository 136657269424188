import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import Image from '../Image';
import { NON_TOGGLABLE } from './BaseFilter.constants';
import styles from './BaseFilter.module.scss';
import { usePresenter } from './BaseFilter.presenter';
import type { BaseFilterProps, FilterOption } from './BaseFilter.types';

export const BaseFilter = <TFilterState, TFilterOptionValue>(props: BaseFilterProps<TFilterState, TFilterOptionValue>) => {
  const {
    filterState,
    displayValue,
    displayValueColour,
    toggleFilterDropdown,
    isExpanded,
    filterDropdownTopContent,
    filterOptions,
    onFilterOptionSelected,
    isClearFilterButtonShown,
    clearFilter,
    filterIconStyle,
    isDisabled,
    isHighlightOnExpandOnly = false,
    theme,
    className,
    classes,
    ariaLabel,
  } = usePresenter(props);

  const { t } = useTranslation();

  const dropdownToggle: React.ReactNode = (
    <Dropdown.Toggle
      as='div'
      aria-haspopup={undefined}
      aria-expanded={undefined}
      className={cx(styles.filterToggleWrapper, { [styles.highlighted]: isHighlightOnExpandOnly ? isExpanded : (filterState || isExpanded) }, classes?.filterToggleWrapper)}
      disabled={isDisabled}
    >
      <Button
        type={isClearFilterButtonShown ? 'Text' : 'TextIcon'}
        style='Text'
        size='Medium'
        text={{
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: displayValueColour,
          align: 'Left',
          value: displayValue,
          className: styles.displayValue,
          classes: classes?.displayValue ? { value: classes.displayValue } : undefined,
        }}
        icon={{
          asset: !isDisabled && isExpanded ? 'ChevronUp' : 'ChevronDown',
          style: filterIconStyle,
          className: cx(styles.filterIcon, classes?.filterIcon),
        }}
        // Need to pass in an empty function to render as a button
        // Dropdown toggle is handled by Dropdown component
        onClick={() => { }}
        className={cx(styles.filterButton, styles.filterToggle, { [styles.split]: isClearFilterButtonShown }, classes?.filterToggle)}
        classes={{ content: styles.filterToggleContent }}
        ariaLabel={ariaLabel || displayValue}
        aria-haspopup={true}
        aria-expanded={isExpanded}
        disabled={isDisabled}
      />
      {isClearFilterButtonShown && (
        <Button
          type='Icon'
          style='Text'
          size='Medium'
          icon={{
            asset: 'Close',
            style: filterIconStyle,
            className: cx(styles.filterIcon, NON_TOGGLABLE, classes?.filterIcon),
          }}
          onClick={clearFilter}
          className={cx(styles.filterButton, styles.clearFilterButton, NON_TOGGLABLE)}
          ariaLabel={t('dateFilters.clearFilter')}
          disabled={isDisabled}
        />
      )}
    </Dropdown.Toggle>
  );

  // Do not apply dropdown menu CSS class if there are no filter options
  const dropdownMenuClassName: string | undefined = filterOptions.length ? styles.dropdownMenu : undefined;

  /**
   * Wrapping the Dropdown Menu with React Portal to ensure it is rendered outside of the scrollable container
   * This allows the dropdown to open correctly, without being constrained by any overflow or scrolling behavior
   * of its parent container. The dropdown will be rendered directly into the document body instead of inside the scrollable container.
   */
  const dropdownMenu: React.ReactNode = isExpanded && (
    createPortal(
      <Dropdown.Menu
        className={cx(styles.dropdownMenuBase, dropdownMenuClassName, NON_TOGGLABLE)}
      >
        {/* Render optional content above filter options when filter dropdown is expanded */}
        {filterDropdownTopContent}

        {/* Loop through filter options and render each filter option */}
        {filterOptions.map((filterOption: FilterOption<TFilterOptionValue>, index: number) => {
          const { value, iconAsset, textKey, isSelected, imageUrl } = filterOption;

          const text: string = t(textKey);

          /** Icon (optional) + text */
          const dropdownMenuItemContent: ReactNode = (
            <div className={styles.dropdownMenuItemContent}>
              {/* Render image if imageUrl is provided */}
              {!!imageUrl && (
                <Image
                  imageSrc={imageUrl}
                  className={styles.dropdownMenuItemImage}
                />
              )}
              {/* Render icon if icon asset is provided */}
              {!!iconAsset && (
                <Icon
                  asset={iconAsset}
                  style={isSelected ? 'ActionBase' : 'SubduedDark'}
                  className={styles.dropdownMenuItemIcon}
                />
              )}
              <Text
                size='Large'
                style='Regular'
                colour={isSelected ? 'ActionBase' : 'SubduedDark'}
                value={text}
                className={styles.dropdownMenuItemText}
              />
            </div>
          );

          /** Green checkmark if the option is selected */
          const selectedIcon = isSelected && (
            <Icon className={styles.checkMarkIcon} asset='CheckmarkInCircleFilled' style='Green60' />
          );

          // Apply onClick to the entire menu option to handle selection
          return (
            <Dropdown.Item
              key={index}
              as='button'
              className={cx(styles.dropdownMenuItem, { [styles.selected]: isSelected })}
              onClick={() => onFilterOptionSelected(value, text)}
            >
              {/* Render the content */}
              {dropdownMenuItemContent}
              {/* Render green checkmark if the option is selected */}
              {selectedIcon}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>,
      document.body,
    )
  );

  return (
    <Dropdown
      show={isExpanded}
      className={cx(styles.filter, styles[theme], className)}
      onToggle={toggleFilterDropdown}
    >
      {dropdownToggle}
      {dropdownMenu}
    </Dropdown>
  );
};
